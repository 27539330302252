export default () => ({
  input: {
    fontSize: 12,
    padding: '10px 15px'
  },
  inputDiv: {
    padding: 0,
    width: 'calc(100% - 48px)'
  },
  root: {
    borderRadius: 0,
    padding: 0,
    width: '100%'
  },
  rootMain: {
    padding: 10,
    width: '100vw'
  }
});
