import { FULFILLED, REJECTED } from 'ducks/actionTypes';
import { delay, put } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import history from '~/history';

const SEARCH_INPUT_DELAY = 200;

export const handleError = (TYPE, error) => {
  if (error.response) {
    if (error.response.status === 401) {
      return { type: 'LOGOUT' };
    } else {
      return { type: TYPE + REJECTED, payload: error.response };
    }
  } else {
    return { type: TYPE + REJECTED, payload: 'Unknown error' };
  }
};

export const create = (TYPE, url) => function* ({ payload: data, meta: redirect }) {
  try {
    const response = yield apiRequest(`${url}`, 'POST', data);
    yield put({ type: TYPE + FULFILLED, payload: response.data });
    if (typeof redirect === 'string') {
      history.push(redirect);
    } else if (typeof redirect === 'function') {
      redirect();
    } else {
      history.goBack();
    }
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};

export const fetch = (TYPE, url) => function* ({ payload: id }) {
  try {
    let targetUrl = id ? `${url}/${id}` : url;
    if (url.includes(':id') && id) {
      targetUrl = url.replace(':id', id);
    }
    const response = yield apiRequest(targetUrl);
    yield put({ type: TYPE + FULFILLED, payload: response.data, meta: { refresh: !id } });
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};

export const fetchWithParams = (TYPE, url) => function* ({ payload: params, meta }) {
  try {
    let queryParams = '';
    Object.keys(params || {}).forEach(key => {
      if (key && params[key]) {
        // don't add & the first time
        if (queryParams) {
          queryParams += '&';
        }
        queryParams += `${key}=${params[key]}`;
      }
    });

    const response = yield apiRequest(queryParams ? url + '?' + queryParams : url);
    yield put({
      type: TYPE + FULFILLED,
      payload: response.data,
      meta: { refresh: (queryParams === '') || (meta && meta.forcedRefresh) }
    });
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};

export const remove = (TYPE, url) => function* ({ payload: id, meta: redirect }) {
  try {
    yield apiRequest(`${url}/${id}`, 'DELETE');
    if (typeof redirect === 'string') {
      history.push(redirect);
    } else if (typeof redirect === 'function') {
      redirect();
    } else {
      history.goBack();
    }
    yield put({ type: TYPE + FULFILLED, payload: id });
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};

export const update = (TYPE, url) => function* ({ payload, meta: redirect }) {
  try {
    const response = yield apiRequest(`${url}/${payload.id}`, 'PUT', payload.data);
    yield put({ type: TYPE + FULFILLED, payload: response.data });
    if (typeof redirect === 'string') {
      history.push(redirect);
    } else if (typeof redirect === 'function') {
      redirect();
    } else {
      history.goBack();
    }
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};

export const searchDebounce = (TYPE, options = {}) => function* () {
  try {
    yield delay(options.searchInputDelay || SEARCH_INPUT_DELAY);
    yield put({ type: TYPE });
  } catch (error) {
    console.error(error);
  }
};

export const poolApi = (TYPE, path) => function* ({ payload: id }) {
  try {
    const response = yield apiRequest(path.replace(':id', id));
    yield put({ type: TYPE + FULFILLED, payload: response.data });
  } catch (error) {
    yield put(handleError(TYPE, error));
  }
};
