import { fade } from '@material-ui/core/styles/colorManipulator';
import { PANEL_HEADER, SPACER_MINI } from 'utils/ui/constants';

export default theme => ({
  buildingsCollapsed: {
    width: '100%',
    height: PANEL_HEADER + SPACER_MINI
  },
  buildingsExpanded: {
    width: '100%'
  },
  contentExpansionPanel: {
    display: 'block'
  },
  expanded: {
    minHeight: '32px !important'
  },
  expandIcon: {
    marginRight: -12
  },
  expansionPanelDetails: {
    height: 'calc(100vh - 96px)',
    overflow: 'auto',
    padding: 0,
    width: '100%'
  },
  heading: {
    fontSize: '0.65rem',
    fontWeight: 'bold',
    lineHeight: 32
  },
  nav: {
    padding: 0,
    width: '100%'
  },
  rounded: {
    '&:first-child': {
      borderRadius: 0
    },
    '&:last-child': {
      borderRadius: 0
    }
  },
  rootExpansionPanel: {
    height: PANEL_HEADER,
    minHeight: PANEL_HEADER,
    padding: '0 12px 0 16px'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  listItem: {
    borderBottom: '1px solid #DDDDDD',
    '&:nth-of-type(2n + 1)': {
      background: '#EDF3FE'
    },
    '&:first-child': {
      borderTop: '1px solid #DDDDDD'
    }
  },
  listText: {
    fontSize: '0.65rem',
    padding: 0
  },
  smallButtons: {
    padding: theme.spacing.unit,
    fontSize: 2 * theme.spacing.unit,
    color: fade(theme.palette.primary.dark, 0.8)
  }
});
