import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/deviceLogs';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formSelectors from 'selectors/form';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const INTERVENTION_TYPES = {
  'Preventive Maintenance': '[DURATION] \n[DESCRIPTION] ',
  'Corrective Maintenance': '[DURATION] \n[DESCRIPTION] ',
  'Work Order': '[DURATION] \n[DESCRIPTION] ',
  'Commissioning': ''
};

const DEFAULT_COMMISSIONING = '[TI MAX CURRENT] A\n[TI TYPE] MONOPHASE / TRIPHASE\n[METER L1] A\n[METER L2] A\n[METER L3] A\n[METER PFT] \n[CLAMP L1] A\n[CLAMP L2] A\n[CLAMP L3] A';
const WISE_BOX_COMMISSIONING = '[Modbus] OK / NOTOK\n[1-Wire] OK / NOTOK\n[WiseOutput 1] OK / NOTOK\n[WiseOutput 2] OK / NOTOK\n';

const Component = props => {
  const { currentUser, device, formValues, loading } = props;
  const { change, create, onSave } = props;

  useEffect(() => {
    let description = '';
    description += `[TYPE] ${formValues.type}\n`;
    description += `[USER] ${currentUser.name}\n`;

    description += (INTERVENTION_TYPES[formValues.type] || '');
    if (formValues.type === 'Commissioning') {
      description += device.wise_box ? WISE_BOX_COMMISSIONING : DEFAULT_COMMISSIONING;
    }

    change('form', 'description', description);
  }, [formValues.type]);

  const formSchema = [
    {
      label: 'Intervention Date\x2a',
      name: 'intervention_at',
      transformation: value => moment(value, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:ss'),
      type: 'dateTime',
      validate: [validators.required]
    },
    {
      disabled: !!formValues.description,
      formData: Object.keys(INTERVENTION_TYPES).map(it => ({ id: it, name: it })),
      label: 'Type*',
      name: 'type',
      type: 'select',
      validate: [validators.required]
    },
    {
      disabled: !formValues.type,
      label: 'Description\x2a',
      name: 'description',
      validate: [validators.required],
      rows: 16,
      type: 'textArea'
    }
  ];

  const onSubmit = values => {
    const data = {
      ...serializeForm(values, formSchema),
      ...{ device_id: device.id, type: 'intervention' }
    };
    create(data, () => onSave());
  };

  const formInitialValues = isEmpty(formValues) ? { description: '', intervention_at: moment().format('YYYY-MM-DDTHH:mm') } : undefined;

  return (
    <FormViewer label={'Add Intervention Form'}>
      <Form
        formSchema={formSchema}
        isSubmitting={loading}
        onSubmit={onSubmit}
        formInitialValues={formInitialValues}
      />
    </FormViewer>
  );
};

const mapStateToProps = state => ({
  currentUser: state.users.models[state.auth.currentUserId],
  formValues: formSelectors.getFormValues(state),
  loading: state.deviceLogs.loading
});

const mapDispatchToProps = ({
  change,
  create: actions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
