import { HEADER_HEIGHT } from 'utils/ui/constants';

export default theme => ({
  leftContainer: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.main
  },
  rightContainer: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  }
});
