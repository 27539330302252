export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2
  },
  chip: {
    margin: theme.spacing.unit / 2
  },
  rootChip: {
    height: 22
  },
  icon: {
    height: 20
  },
  label: {
    fontSize: '0.65rem'
  }
});