import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';

const content = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    textAlign: 'justify',
    fontSize: 12
  }
});

const snackbar = theme => ({
  margin: {
    margin: theme.spacing.unit
  }
});

export default {
  snackbar,
  content
};
