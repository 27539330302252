import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/users';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const formSchema = [
  {
    type: 'text',
    label: 'Name\x2a',
    name: 'name',
    validate: [validators.required]
  },
  {
    type: 'text',
    label: 'Email\x2a',
    name: 'email',
    validate: [validators.required]
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password'
  },
  {
    type: 'password',
    label: 'Password Confirmation',
    name: 'password_confirmation'
  },
  {
    type: 'checkBox',
    label: 'Enabled?',
    name: 'enabled'
  },
  {
    type: 'checkBox',
    label: 'Daily Report?',
    name: 'report'
  }
];

const Component = props => {
  const { model, submitting } = props;
  const { update } = props;

  return (
    <EntityManageView
      formSchema={formSchema}
      formInitialValues={model}
      label='Edit User'
      submitting={submitting}
      update={update}
    />
  );
};

const mapStateToProps = state => ({
  model: state.users.models[state.drawerView.entityId],
  submitting: state.users.submitting
});

const mapDispatchToProps = ({
  update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
