import { TABS_HEIGHT } from 'utils/ui/constants';

export default theme => ({
  boxShadowContainer: {
    height: TABS_HEIGHT,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px' +
      ' rgba(0,0,0,0.12)'
  },
  tabs: {
    width: 'fit-content'
  },
  paper: {
    paddingLeft: 20,
    display: 'flex',
    height: '100%'
  }
});
