// actions
import { isString } from 'lodash';
import { CLEAN_ALERT, FULFILLED, REJECTED } from './actionTypes';

export const cleanUserFeedback = () => ({
  type: CLEAN_ALERT
});

const DEFAULT_ERROR = 'Unrecognized error, please contact us!';
const DEFAULT_SUCCESS = 'Operation successful!';
// regex needed for UPLOAD to work
const IGNORED_FULFILLED_ACTIONS = ['BOOT', 'FETCH', /^LOAD/, 'LOGIN', 'SEARCH'];
const IGNORED_REJECTED_ACTIONS = ['BOOT', 'FETCH', /^LOAD/];

// reducer
const INITIAL_STATE = {
  message: '',
  type: 'error', // 'success', 'error', 'warning', 'info'
  timeout: 5000,
  placement: { vertical: 'bottom', horizontal: 'left' }
};

export default (state = INITIAL_STATE, { type, payload }) => {
  let message = '';

  if (type === CLEAN_ALERT) {
    return { ...state, message };
  }

  if (type.match(FULFILLED)) {
    if (IGNORED_FULFILLED_ACTIONS.some(kind => type.match(kind))) {
      return state;
    }

    return { ...state, message: DEFAULT_SUCCESS, type: 'success' };
  }

  if (type.match(REJECTED)) {
    if (IGNORED_REJECTED_ACTIONS.some(kind => type.match(kind))) {
      return state;
    }

    if (type === 'LOGIN_REJECTED') {
      if (isString(payload)) {
        message = payload;
      } else {
        message = payload && payload.response && payload.response.data && payload.response.data.global;
      }
    } else if (payload && payload.data && payload.data.errors) {
      message = Object.keys(payload.data.errors).map(error => `${error}: ${payload.data.errors[error]}`).join(' + ');
    } else if (payload && payload.data && payload.data.error) {
      message = payload.data.error;

    }

    return { ...state, message: message || DEFAULT_ERROR, type: 'error' };
  }

  return state;
};
