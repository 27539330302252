import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';

const Component = ({ icon, ...rest }) => <FontAwesomeIcon icon={Array.isArray(icon) ? icon : icons[icon]} {...rest} />;

export default Component;

Component.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
