import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import UploadFtt from 'Components/UploadFtt';
import { actions as deviceActions } from 'ducks/devices';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import Table from './Table';

const Component = props => {
  const { device, submitting } = props;
  const { deleteFuture } = props;

  const [drawer, setOpenDrawer] = useState(null);
  const closeDrawer = () => setOpenDrawer(null);

  let buttons = [];

  if (device.wise_box) {
    buttons = [
      {
        icon: icons.bug,
        confirmationHeader: 'Delete Confirmation',
        confirmationText: 'Are you sure you want to delete future data?',
        submitting: submitting,
        onClick: () => deleteFuture(device.id),
        tooltip: 'Delete future data'
      },
      {
        icon: icons.cloudUpload,
        onClick: () => setOpenDrawer('uploadFtt'),
        tooltip: 'Upload FTT files'
      }
    ];
  }

  let DrawerContent;
  if (drawer === 'uploadFtt') {
    DrawerContent = (
      <UploadFtt deviceId={device.id} onSave={closeDrawer}/>
    );
  }

  const panelFields = { buttons };

  let TableComponent = (
    <Table device={device}/>
  );

  return (
    <React.Fragment>
      <Panel {...panelFields}>
        {TableComponent}
      </Panel>
      <Drawer big={drawer === 'showIndicator'} onClose={closeDrawer} open={!!drawer}>
        {DrawerContent}
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  deleteFuture: deviceActions.deleteFuture
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
