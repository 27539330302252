export default (columns = []) => {
  const SEARCHABLE_KEYS = columns.filter(column => column.searchable !== false).map(column => column.dataKey);

  return {
    allChecked: false,
    checked: new Set,
    ids: [],
    models: {},
    loaded: false,
    loading: false,
    errorMessage: '',
    searching: false,
    searchValue: '',
    sortBy: SEARCHABLE_KEYS[0],
    sortDirection: 'ASC',
    searchableKeys: SEARCHABLE_KEYS,
    submitting: false
  };
};
