const TOOLBAR_HEIGHT = '48px';

export default theme => ({
  button: {
    fontSize: 20
  },
  menu: {
    height: 48,
    paddingLeft: 3,
    paddingTop: 5
  },
  search: {
    height: 48,
    padding: 0,
    paddingTop: 7
  },
  summary: {
    ...theme.typography.button,
    fontSize: 10,
    height: 48,
    lineHeight: '48px',
    paddingRight: 25,
    textAlign: 'right',
    textTransform: 'none'
  },
  table: {
    height: `calc(100% - ${TOOLBAR_HEIGHT})`
  }
});
