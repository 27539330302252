export default [
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Name',
    dataKey: 'name'
  },
  {
    width: 80,
    flexGrow: 1.0,
    label: 'Type',
    dataKey: 'metadata_type_name',
    disableSort: true
  },
  {
    width: 140,
    flexGrow: 1.0,
    label: 'Admissible Values',
    dataKey: 'enumerator_members',
    cellContentRenderer: item => item ? item : '---'
  }
];
