export default theme => ({
  heading: {
    //fontSize: theme.typography.pxToRem(15),
    fontSize: '0.75rem',
    fontWeight: 'bold'
  },
  expandedExpansionPanel: {
    margin: `${theme.spacing.unit}px 0px`,
    '&:first-child': {
      borderRadius: 0
      //boxShadow: 'none',
    },
    '&:last-child': {
      borderRadius: 0
      //boxShadow: 'none',
    }
  },

  expansionPanelDetailsWithHeader: {
    paddingTop: 0
  },
  expansionPanelDetailsWithoutHeader: {
    paddingTop: 16
  }
});
