import AddToExistingIndicator from 'Components/AddToExistingIndicator';
import IconButton from 'Components/Buttons/IconButton';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions as indicatorsActions } from 'ducks/indicators';
import { loadBuilding } from 'ducks/loadBuilding';
import EditIndicator from 'Pages/Indicators/Edit';
import NewIndicator from 'Pages/Indicators/New';
import ShowIndicator from 'Pages/Indicators/Show';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import sortMeasurements from 'utils/sortMeasurements';
import statusToColor from 'utils/statusToColor';
import styleTime from 'utils/styleTime';
import icons from 'utils/ui/icons';

const Component = props => {
  const { device, indicators, measurements, submitting } = props;
  const { loadBuilding, remove, unlinkMeasurement } = props;

  const [currentDrawer, setDrawer] = useState(false);
  const [entity, setEntity] = useState(null);

  const onClose = () => setDrawer(false);
  const onClick = (entity, drawer) => event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setEntity(entity);
    setDrawer(drawer);
  };

  const columns = [
    { label: 'Status', dataKey: 'status' },
    { label: 'Measurement Name', dataKey: 'measurementName' },
    { label: 'Indicator Name', dataKey: 'indicatorName' },
    { label: 'Actions', dataKey: 'actions' }
  ];

  const rows = measurements.sort(sortMeasurements).map(measurement => {
    const row = {}, actions = [];

    row.id = measurement.id;
    row.measurementName = measurement.name;

    if (measurement.indicator_id) {
      const indicator = indicators[measurement.indicator_id];

      row.status = `started at ${measurement.indicator_start}`;
      row.status = (
        <React.Fragment>
        <span style={{ color: statusToColor('ok') }}>
          started at @ {styleTime(measurement.indicator_start)}
        </span>
        </React.Fragment>
      );

      row.indicatorName = indicator.name;

      actions.push(
        <IconButton icon={icons.details} key='details' onClick={onClick(measurement, 'showIndicator')} tooltip='Show Details'/>
      );

      if (measurement.indicator_stop) {
        row.status = (
          <React.Fragment>
            <span style={{ color: statusToColor('stopped') }}>
              stopped @ {styleTime(measurement.indicator_stop)}
            </span>
          </React.Fragment>
        );

        actions.push(
          <IconButton icon={icons.edit} key='edit' onClick={onClick(indicator, 'editIndicator')} tooltip='Edit Indicator'/>
        );

        if (!indicator.used) {
          actions.push(
            <IconButton
              confirmationText='Are you sure you want to remove? This can not be undone.'
              icon={icons.trash}
              key='remove'
              onClick={() => remove(indicator.id, () => loadBuilding(indicator.building_id))}
              submitting={submitting}
              tooltip='Remove Indicator'
            />
          );
        }
      } else {
        actions.push(
          <IconButton icon={icons.edit} key='edit' onClick={onClick(indicator, 'editIndicator')} tooltip='Edit Indicator'/>
        );

        actions.push(
          <IconButton
            confirmationText='Are you sure you want to unlink?'
            icon={icons.stop}
            key='unlink'
            onClick={() => unlinkMeasurement({ indicatorId: measurement.indicator_id, measurementId: measurement.id })}
            submitting={submitting}
            tooltip='Unlink Indicator'
          />
        );
      }
    } else {
      row.status = (
        <span style={{ color: statusToColor('default') }}>unused</span>
      );

      row.indicatorName = '-';

      actions.push(
        <IconButton icon={icons.link} key='link' onClick={onClick(measurement, 'linkIndicator')} tooltip='Link Existing Indicator'/>,
        <IconButton icon={icons.start} key='create' onClick={onClick(measurement, 'newIndicator')} tooltip='Create Indicator'/>
      );
    }

    row.actions = (
      <React.Fragment>
        {actions}
      </React.Fragment>
    );

    return row;
  });

  let drawer;
  switch (currentDrawer) {
    case 'editIndicator':
      drawer = <EditIndicator indicator={entity} closeDrawer={onClose}/>;
      break;
    case 'linkIndicator':
      drawer = <AddToExistingIndicator measurement={entity} closeDrawer={onClose}/>;
      break;
    case 'newIndicator':
      drawer = <NewIndicator device={device} measurement={entity}/>;
      break;
    case 'showIndicator':
      drawer = <ShowIndicator actions={false} closeDrawer={onClose} id={entity.indicator_id}/>;
      break;
  }

  return (
    <React.Fragment>
      <Drawer big={currentDrawer === 'showIndicator'} onClose={onClose} open={!!currentDrawer}>
        {drawer}
      </Drawer>
      <Panel>
        <Table columns={columns} rows={rows}/>
      </Panel>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  readings: Object.values(state.readings.models).filter(reading => reading.equipment_id === ownProps.device.equipment_id),
  submitting: state.measurements.submitting || state.indicators.submitting
});

const mapDispatchToProps = {
  loadBuilding: loadBuilding,
  remove: indicatorsActions.remove,
  unlinkMeasurement: indicatorsActions.unlinkMeasurement
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
