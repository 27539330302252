import { unixToDate } from 'utils/date';

export default [
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Name',
    dataKey: 'name'
  },
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Organization',
    dataKey: 'organization_name'
  },
  {
    width: 120,
    flexGrow: 1.0,
    label: 'Last Modification',
    dataKey: 'updated_at',
    cellContentRenderer: unixToDate,
    searchable: false
  }
];
