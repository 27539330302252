import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import ShowEquipment from 'Pages/Equipment/Show';
import NewDevice from 'Pages/Tree/Device/New';
import React, { useState } from 'react';
import extractNetworkId from 'utils/extractNetworkId';
import sortDevices from 'utils/sortDevices';
import statusToColor from 'utils/statusToColor';
import icons from 'utils/ui/icons';
import history from '~/history';

const
  initialDrawerState = {},
  onRowClick = id => history.push(`/devices/${id}`),
  sorter = devices => devices.sort(sortDevices);

const Component = props => {
  const { childDevices, device } = props;

  const [currentDrawer, setDrawer] = useState(initialDrawerState);
  const closeDrawer = () => setDrawer(initialDrawerState);

  const buttons = [{ icon: icons.addEntity, onClick: () => setDrawer({ view: 'newChildDevice' }), tooltip: 'New child device' }];

  let networkName = 'Network ID';
  switch (device.equipment_category_name) {
    case 'MBus TCP/IP Bridge':
    case 'WiseMbus':
      networkName = 'Mbus ID';
      break;
    case 'Modbus TCP/IP Bridge':
    case 'WiseModbus':
      networkName = 'Modbus ID';
      break;
    case 'NSI':
      networkName = 'IP Address';
      break;
    case 'WiseOneWire':
      networkName = '1-Wire ID';
      break;
  }

  const columns = [{ label: 'Status', dataKey: 'status', transform: ({ status }) => <span style={{ color: statusToColor(status) }}>{status}</span> }];

  if (!['Modbus Digital Bridge', 'WiseBox', 'WiseBoxNew'].includes(device.equipment_category_name)) {
    columns.push({ label: networkName, dataKey: 'network_id', transform: device => extractNetworkId(device) || '-' });
  }

  if (device.equipment_category_name !== 'WiseBox') {
    columns.push(
      {
        label: 'Name',
        dataKey: 'name'
      }
    );
  }

  columns.push(
    {
      label: 'Equipment', dataKey: 'equipment_name', transform: device => {
        const onClick = event => {
          event.stopPropagation();
          event.preventDefault();

          setDrawer({ view: 'showEquipment', id: device.equipment_id });
        };

        return (
          <a href='#' onClick={onClick}>{device.equipment_name}</a>
        );
      }
    },
    {
      label: 'ID',
      dataKey: 'id'
    }
  );

  let drawer;
  switch (currentDrawer.view) {
    case 'newChildDevice':
      drawer = <NewDevice buildingId={device.building_id} parentDeviceId={device.id} onSave={closeDrawer}/>;
      break;
    case 'showEquipment':
      drawer = <ShowEquipment disableButtons id={currentDrawer.id}/>;
      break;
  }

  return (
    <React.Fragment>
      <Panel buttons={buttons}>
        <Table columns={columns} onRowClick={onRowClick} rows={sorter(childDevices)}/>
      </Panel>
      <Drawer onClose={closeDrawer} open={!!drawer}>
        {drawer}
      </Drawer>
    </React.Fragment>
  );
};

export default Component;
