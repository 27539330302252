import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SpinButton from 'Components/Buttons/Button';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
  const { alert, alertHeader = '', open, submitting } = props;
  const { onConfirm, onClose, onNo } = props;

  const handleAction = () => onConfirm();

  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : () => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {alertHeader}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alert}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="primary" variant="contained" disabled={submitting}>
          No
        </Button>
        <SpinButton onClick={handleAction} label='Yes' submitting={submitting}/>

      </DialogActions>
    </Dialog>
  );
};

export default Component;

Component.propTypes = {
  alert: PropTypes.string,
  alertHeader: PropTypes.string,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
