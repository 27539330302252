export default device => {
  const ipMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'ip');
  if (ipMetadata) {
    return ipMetadata.value;
  }

  const mbusMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'mbus address');
  if (mbusMetadata) {
    return mbusMetadata.value;
  }

  const modbusMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'modbus id');
  if (modbusMetadata) {
    return modbusMetadata.value;
  }

  const idMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'id');
  if (idMetadata) {
    return idMetadata.value;
  }

  return null;
};
