import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

const protocols = state => state.protocols.models;

const formInputProtocolId = state => {
  if (state.form) {
    const formName = Object.keys(state.form)[0];
    if (state.form[formName] && state.form[formName].values) {
      return state.form[formName].values.in_protocol_id;
    }
  }
  return null;
};

const protocolsArray = createSelector(
  protocols,
  protocols => sortBy(Object.values(protocols), 'name')
);

export const formProtocolsOutFromProtocolsIn = createSelector(
  formInputProtocolId,
  protocols,
  (inputProtocolId, protocols) => {
    if (inputProtocolId) {
      const outProtocols = protocols[inputProtocolId].out_protocol_ids.map(id => protocols[id]);
      return sortBy(outProtocols, 'name');
    }
    return [];
  }
);

export default {
  formProtocolsOutFromProtocolsIn,
  protocolsArray
};
