import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import icons from 'utils/ui/icons';
import ChipsBox from './ChipsBox';
import styles from './styles';

const Component = props => {
  const { classes, disabled, input, name, label, meta } = props;
  const [newOption, setNewOption] = useState('');

  const handleDelete = data => () => {
    if (!disabled) {
      const chipData = Array.isArray(input.value) ? [...input.value] : [];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);
      input.onChange(chipData);
    }
  };

  const handleAddMember = () => {
    if (newOption) {
      const chipData = Array.isArray(input.value) ? [...input.value] : [];
      chipData.push(newOption);
      setNewOption('');
      input.onChange(chipData);
    }
  };

  const handleOnBlur = () => {
    input.onBlur(undefined);
  };

  const handleNewOptionChange = e => {
    setNewOption(e.target.value);
  };

  return (
    <Grid container className={classes.itemGeneratorBox}>
      <Grid item xs={10} style={{ paddingTop: 10 }}>
        <TextField
          autoComplete='off'
          className={classes.textField}
          disabled={disabled}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          id={name}
          label={label}
          margin='normal'
          onChange={handleNewOptionChange}
          onBlur={handleOnBlur}
          onFocus={input.onFocus}
          value={newOption}
          variant='filled'
          inputProps={{
            style: {
              fontSize: '0.65rem'
            }
          }}
          InputProps={{
            style: {
              height: 30,
              //paddingTop: 10,
              paddingBottom: 16
            }
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              transform: 'translate(12px, -15px) scale(0.75)'
            }
          }}
        />

      </Grid>
      <Grid item xs={2} className={classes.iconButton}>
        <Fab
          aria-label="Add"
          classes={{ sizeSmall: classes.sizeSmallFab }}
          color="primary"
          disabled={disabled}
          size="small"
        >
          <Icon icon={icons.addSimple} onClick={handleAddMember}/>
        </Fab>
      </Grid>

      <Grid item xs={12}>
        <ChipsBox chips={Array.isArray(input.value) ? [...input.value] : []} handleDelete={handleDelete}/>
      </Grid>
    </Grid>

  );
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
  label: PropTypes.string,
  meta: PropTypes.object, //injected by Field from Redux forms to access interaction state
  name: PropTypes.string
};
