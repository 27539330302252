import { orderBy } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { FULFILLED, REJECTED } from './actionTypes';

const FETCH_DEVICE_LOGS = 'FETCH_DEVICE_LOGS';
const CLEAR_DEVICE_LOGS = 'CLEAR_DEVICE_LOGS';
const CREATE_LOG = 'CREATE_LOG';

// actions
const actions = ({
  clear: () => ({
    type: CLEAR_DEVICE_LOGS
  }),
  create: (payload, meta) => ({
    meta,
    payload,
    type: CREATE_LOG
  }),
  fetchAll: params => ({
    type: FETCH_DEVICE_LOGS,
    payload: params
  })
});

export { actions };

// reducer
const INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEVICE_LOGS:
      return { ...state, loading: true };

    case FETCH_DEVICE_LOGS + FULFILLED:
      return { ...state, loading: false, data: action.payload };

    case FETCH_DEVICE_LOGS + REJECTED:
      return { ...state, error: action.error, loading: false };

    case CREATE_LOG:
      return { ...state, loading: true };

    case CREATE_LOG + FULFILLED:
      const data = orderBy([...state.data, action.payload], ['created_at'], ['desc']);
      return { ...state, data, loading: false };

    case CREATE_LOG + REJECTED:
      return { ...state, error: action.error, loading: false };

    case CLEAR_DEVICE_LOGS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

// sagas
function* watchCreateLog() {
  yield takeLatest(CREATE_LOG, function* ({ payload, meta }) {
    try {
      const response = yield apiRequest('/device_logs', 'POST', payload);
      yield put({ type: CREATE_LOG + FULFILLED, payload: response.data });
      if (typeof meta === 'function') {
        meta();
      }
    } catch (error) {
      yield put(handleError(CREATE_LOG, error));
    }
  });
}

function* watchFetchStatuses() {
  yield takeLatest(FETCH_DEVICE_LOGS, function* ({ payload: params }) {
    let queryParams = '';
    Object.keys(params || {}).forEach(key => {
      if (key && params[key]) {
        queryParams += queryParams ? '&' : '?';
        queryParams += `${key}=${params[key]}`;
      }
    });

    try {
      const response = yield apiRequest('/device_logs' + queryParams);
      yield put({
        type: FETCH_DEVICE_LOGS + FULFILLED,
        payload: response.data
      });
    } catch (error) {
      yield put(handleError(TYPE, error));
    }
  });
}

const sagas = [
  fork(watchCreateLog),
  fork(watchFetchStatuses)
];

export { sagas };
