import Table from 'Components/SimpleTable';
import { actions as deviceActions } from 'ducks/devices';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import statusToColor from 'utils/statusToColor';
import styleTime from 'utils/styleTime';

const
  collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }),
  transformDataValue = ({ last_acquisition_value: value }) => value === null || value === undefined ? 'N/A' : value,
  transformLastAcquisition = ({ last_acquisition_timestamp }) => styleTime(last_acquisition_timestamp),
  transformStatus = ({ status }) => <span style={{ color: statusToColor(status) }}>{status}</span>;

const columns = [
  { label: 'Name', dataKey: 'name' },
  { label: 'Count', dataKey: 'acquisition_count' },
  { label: 'Last Value', dataKey: 'last_acquisition_value', transform: transformDataValue },
  { label: 'Last Acquisition', dataKey: 'last_acquisition_timestamp', transform: transformLastAcquisition },
  { label: 'Status', dataKey: 'status', transform: transformStatus }
];

const Component = props => {
  const { device, loading, rows } = props;
  const { startPoolingDataLogs, stopPoolingDataLogs } = props;

  useEffect(() => {
    startPoolingDataLogs(device.id);

    return () => stopPoolingDataLogs(device.id);
  }, []);

  const sortedRows = rows.sort((a, b) => collator.compare(a.name, b.name));

  return (
    <Table columns={columns} loading={loading} rows={sortedRows}/>
  );
};

const mapStateToProps = state => ({
  loading: state.logs.loading,
  rows: state.logs.data,
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  startPoolingDataLogs: logActions.startPoolingDataLogs,
  stopPoolingDataLogs: logActions.stopPoolingDataLogs
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
