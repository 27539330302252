import Note from 'Components/Note';
import Panel from 'Components/Panel';
import Loading from 'Components/ScopedLoader';
import Table from 'Components/SimpleTable';
import { actions } from 'ducks/measurements';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';

const columns = [
  { label: 'Timestamp', dataKey: 'timestamp' },
  { label: 'Value', dataKey: 'value' }
];

const Component = props => {
  const { data, loading, measurement } = props;
  const { fetchData } = props;

  useEffect(() => {
    fetchData(measurement.id);
  }, []);

  if (loading) {
    return (
      <Panel>
        <Loading/>
      </Panel>
    );
  }

  const rows = data.map(row => ({
    id: row.t,
    timestamp: unixToDate(row.t),
    value: row.v
  }));

  return (
    <Panel>
      <Note>Note: Only the last 100 rows will be shown here</Note>
      <Table columns={columns} rows={rows} rowsPerPage={100}/>
    </Panel>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: state.measurements.data,
  loading: state.measurements.loading,
  measurement: state.measurements.models[ownProps.id]
});

const mapDispatchToProps = {
  fetchData: actions.fetchData
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
