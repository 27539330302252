import Circle from 'Components/Circle';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import PanelDivider from 'Components/PanelDivider';
import SimpleList from 'Components/SimpleList';
import { actions as deviceActions } from 'ducks/devices';
import EditDevice from 'Pages/Tree/Device/Edit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { unixToDate } from 'utils/date';
import formatDescription from 'utils/formatDescription';
import getUserName from 'utils/getUserName';
import statusToComponent from 'utils/statusToComponent';
import icons from 'utils/ui/icons';
import globals from '~/globals';
import CreateTemplate from './CreateTemplate';

const generateCurrentConfiguration = device => {
  return (
    <React.Fragment>
      <Link
        onClick={event => {
          event.preventDefault();
          window.open(`${globals.apiUrl}/wise_boxes/${device.id}/configuration`);
        }}
        to='/'
      >
        {device.configuration_md5}
      </Link>
      &nbsp;| {unixToDate(device.configuration_updated_at)}
    </React.Fragment>
  );
};

const generateLastDeploy = device => {
  const color = device.remotely_synced ? '#2C6700' : '#B94A48';

  return (
    <React.Fragment>
      <Circle color={color}/>
      &nbsp;
      {device.configuration_last_deploy ? unixToDate(device.configuration_last_deploy) : 'Never deployed'}
    </React.Fragment>
  );
};

const generateLastModification = device => {
  const color = device.locally_synced ? '#2C6700' : '#B94A48';

  return (
    <React.Fragment>
      <Circle color={color}/>
      &nbsp;
      {unixToDate(device.configuration_last_modification)}
    </React.Fragment>
  );
};

const generateRegistered = device => {
  const color = device.registered_at ? '#2C6700' : '#B94A48';

  return (
    <React.Fragment>
      <Circle color={color}/>
      &nbsp;
      {device.registered_at ? unixToDate(device.registered_at) : 'Not yet registered'}
    </React.Fragment>
  );
};

const Component = props => {
  const { childDevices, device, submitting } = props;
  const { fetchOne, remove, syncWiseBox } = props;

  const [drawer, setOpenDrawer] = useState(null);
  const closeDrawer = () => setOpenDrawer(null);

  useEffect(() => {
    if (device.new_wise_box) {
      fetchOne(device.id);
    }
  }, [device.id]);

  const deviceAttributes = [
    { label: 'ID', content: device.id },
    { label: 'Equipment', content: device.equipment_name },
    { label: 'Description', content: formatDescription(device.description) },
    { label: 'Name', content: device.name },
    { label: 'Status', content: statusToComponent(device) },
    { label: 'Created', content: `${unixToDate(device.created_at)} by ${getUserName(device.created_by)}` },
    { label: 'Updated', content: unixToDate(device.updated_at) }
  ];

  const wiseBoxAttributes = [
    { label: 'Balena ID', content: device.balena_id },
    { label: 'Balena UUID', content: device.balena_uuid },
    { label: 'Claimed', content: `${unixToDate(device.claimed_at)} by ${getUserName(device.claimed_by)}` },
    { label: 'Registration Date', content: generateRegistered(device) },
    { label: 'Current Configuration (MD5 | Date)', content: generateCurrentConfiguration(device) },
    { label: 'Last Configuration Change', content: generateLastModification(device) },
    { label: 'Last Deploy', content: generateLastDeploy(device) }
  ];

  const deviceButtons = [], wiseBoxButtons = [];

  deviceButtons.push(
    {
      icon: icons.edit,
      onClick: () => setOpenDrawer('editDevice'),
      tooltip: 'Edit'
    }
  );

  if (childDevices.length === 0) {
    deviceButtons.push(
      {
        confirmationHeader: 'Delete Confirmation',
        confirmationText: 'Are you sure you want to delete?',
        submitting: submitting,
        icon: icons.trash,
        onClick: () => remove(device.id, `/buildings/${device.building_id}`),
        tooltip: 'Delete'
      }
    );
  }

  wiseBoxButtons.push(
    {
      confirmationHeader: 'Operation Confirmation',
      confirmationText: 'Are you sure you want to sync the configuration with WiseBox?',
      //disabled: device.locally_synced,
      icon: icons.sync_alt,
      onClick: () => syncWiseBox(device.id),
      submitting: submitting,
      tooltip: 'Sync configuration files'
    },
    {
      icon: icons.template,
      onClick: () => setOpenDrawer('createTemplate'),
      tooltip: 'Create New Template'
    }
  );

  let DrawerContent;
  if (drawer === 'createTemplate') {
    DrawerContent = (
      <CreateTemplate id={device.id} closeDrawer={closeDrawer}/>
    );
  } else if (drawer === 'editDevice') {
    DrawerContent = (
      <EditDevice id={device.id} closeDrawer={closeDrawer}/>
    );
  }

  return (
    <React.Fragment>
      <Panel buttons={deviceButtons}>
        <SimpleList content={deviceAttributes}/>
      </Panel>
      <PanelDivider/>
      <Panel buttons={wiseBoxButtons}>
        <SimpleList content={wiseBoxAttributes}/>
      </Panel>
      <Drawer onClose={closeDrawer} open={!!drawer}>
        {DrawerContent}
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  fetchOne: deviceActions.fetchOne,
  syncWiseBox: deviceActions.syncWiseBox,
  remove: deviceActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
