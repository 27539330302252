import { Button, Grid, Hidden, Paper, withStyles } from '@material-ui/core';
import Icon from 'Components/Icon';
import { login } from 'ducks/authentication';
import lmitLogo from 'images/logos/big.png';
import wmLogo from 'images/logos/big.png';
import PropTypes from 'prop-types';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import styles from './styles';

// TODO: USE location.state information to redirect to the URL we were coming from after making login in case of
// redirect

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      formData: {
        email: '',
        password: ''
      }
    };
  }

  handleChange(event) {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit() {
    const { formData } = this.state;
    this.props.login(formData.email, formData.password);
  }

  render() {
    const { classes } = this.props;
    const { formData } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.loginArea}>
          <Paper className={classes.padding}>
            <div className={classes.margin}>
              <Grid container justify="center">
                <Grid item xs={3}>
                  <img src={wmLogo} style={{ width: '100%' }} alt=""/>
                </Grid>
              </Grid>

              <ValidatorForm onSubmit={this.handleSubmit}>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item>
                    <Icon color="primary" icon={icons.user} size='2x'/>
                  </Grid>
                  <Grid item md={true} sm={true} xs={true}>
                    <TextValidator
                      label="Email"
                      onChange={this.handleChange}
                      name="email"
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item>
                    <Icon color="primary" icon={icons.password} size='2x'/>
                  </Grid>
                  <Grid item md={true} sm={true} xs={true}>
                    <TextValidator
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      value={formData.password}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      fullWidth
                      type='password'
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: '10px' }}>
                  <Button
                    type="submit"
                    className={classes.textTransformationNone}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClick}
                  >
                    Login
                  </Button>
                </Grid>
              </ValidatorForm>

              <Grid container justify="flex-end">
                <Hidden only='xs'>
                  <Grid item>
                    <img src={lmitLogo} width={'47px'} height={'24px'} alt=""/>
                  </Grid>
                </Hidden>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                  <Grid item>
                    <img src={lmitLogo} width={'33px'} height={'18px'} alt=""/>
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = ({
  login
});

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(LoginPage));
