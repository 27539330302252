import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
  const { classes, disabled, label, loading, submitting, size, type } = props;
  const { onClick } = props;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || loading || submitting}
          onClick={!submitting ? onClick : undefined}
          size={size}
          type={type}
        >
          {label}
        </Button>
        {submitting && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
    </div>
  );
};

Component.defaultProps = {
  disabled: false
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  submitting: PropTypes.bool,
  type: PropTypes.string
};
