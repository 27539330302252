import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/wise_boxes';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import selectorForm from 'selectors/form';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
  const { formValues, organizations, submitting, wise_box } = props;
  const { claim, onClose } = props;

  let
    buildings = Object.values(props.buildings),
    templates = Object.values(props.templates);

  let
    buildingOrganizationIds = [...new Set(buildings.map(b => b.organization_id))],
    templateOrganizationIds = [...new Set(templates.map(b => b.organization_id))];

  const availableOrganizations = organizations.filter(organization => {
    return organization.disabled === false &&
      buildingOrganizationIds.includes(organization.id) &&
      templateOrganizationIds.includes(organization.id);
  });

  const formSchema = [
    {
      disabled: !!(formValues.template_id || formValues.building_id),
      formData: sortBy(availableOrganizations, 'name'),
      label: 'Organization\x2a',
      name: 'organization_id',
      type: 'select',
      validate: [validators.required]
    }
  ];

  if (formValues.organization_id) {
    const buildingData = buildings
      .filter(building => building.active && building.organization_id === formValues.organization_id)
      .map(({ id, name }) => ({ id, name }));

    const templateData = templates
      .filter(template => template.organization_id === formValues.organization_id)
      .map(({ id, name }) => ({ id, name }));

    formSchema.push({
      type: 'text',
      label: 'Name\x2a',
      name: 'name',
      validate: [validators.required]
    });

    formSchema.push({
      type: 'select',
      label: 'Template\x2a',
      name: 'template_id',
      validate: [validators.required],
      formData: sortBy(templateData, 'name')
    });

    formSchema.push({
      type: 'select',
      label: 'Building\x2a',
      name: 'building_id',
      validate: [validators.required],
      formData: sortBy(buildingData, 'name')
    });
  }

  const onSubmit = values => {
    const data = serializeForm(values, formSchema);
    claim(wise_box.id, data, () => onClose());
  };

  return (
    <FormViewer label={'Claim WiseBox'}>
      <Form
        formSchema={formSchema}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = state => ({
  buildings: state.buildings.models,
  formValues: selectorForm.getFormValues(state),
  organizations: state.auth.organizations,
  submitting: state.wise_boxes.submitting,
  templates: state.templates.models,
  wise_box: state.wise_boxes.models[state.drawerView.entityId]
});

const mapDispatchToProps = ({
  claim: actions.claim
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
