import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/templates';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/templates';
import Show from './Show';

const Component = props => {
  return (
    <EntitiesView
      columns={columns}
      showComponent={Show}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection } = state.templates;

  return {
    allChecked, checked, entityType: state.drawerView.entity, ids, models, loading, searching, searchValue, sortBy, sortDirection
  };
};

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
  changeSearch,
  changeSort,
  fetchData: fetchAll,
  toggleAll,
  toggleOne: event => {
    event.stopPropagation();
    return toggleOne(event.target.name);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

