import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/protocols';
import Show from 'Pages/Protocols/Show';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/protocols';

const ProtocolsPage = props => {
  let { currentUser, ...rest } = props;

  return (
    <EntitiesView
      columns={columns}
      showComponent={Show}
      {...rest}
    />
  );
};

const mapStateToProps = ({ protocols: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection }, auth: { currentUser } }) =>
  ({ allChecked, checked, currentUser, ids, models, loading, searching, searchValue, sortBy, sortDirection });

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
  changeSearch,
  changeSort,
  fetchData: fetchAll,
  toggleAll,
  toggleOne: event => {
    event.stopPropagation();
    return toggleOne(event.target.name);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolsPage);

