import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Map from 'Components/Maps';
import { actions } from 'ducks/buildings';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Marker from './Marker';
import Search from './Search';
import styles from './styles';

const buildingStateToMarkers = (ids, models) => {
  return ids.reduce((acc, id) => {
    if (models[id].status !== 'disabled') {
      acc.push({
        lat: models[id].latitude,
        lng: models[id].longitude,
        Component: Marker,
        props: {
          building: models[id]
        }
      });
    }

    return acc;
  }, []);
};

const mapDecorators = [
  { Component: <Search/>, style: { left: 0, position: 'absolute', top: 0 } }
];

const Component = props => {
  const
    { classes, defaultCenter, defaultZoom, ids, models } = props,
    { startPoolingBuildings, stopPoolingBuildings } = props;

  useEffect(() => {
    startPoolingBuildings();

    return stopPoolingBuildings;
  }, []);

  const markers = buildingStateToMarkers(ids, models);

  return (
    <Grid className={classes.container} item xs={12}>
      <Map
        decorators={mapDecorators}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        markers={markers}
      />
    </Grid>
  );
};

Component.defaultProps = {
  defaultCenter: { lat: 38.7390071088303, lng: -9.10513977732148 },
  defaultZoom: 6
};

const mapStateToProps = state => ({
  ids: state.buildings.ids,
  models: state.buildings.models
});

const mapDispatchToProps = ({
  startPoolingBuildings: actions.startPoolingBuildings,
  stopPoolingBuildings: actions.stopPoolingBuildings
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
