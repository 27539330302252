const DRAWER_NEW_ENTITY = 'DRAWER_NEW_ENTITY';
const DRAWER_EDIT_ENTITY = 'DRAWER_EDIT_ENTITY';
const DRAWER_VIEW_ENTITY = 'DRAWER_VIEW_ENTITY';
const DRAWER_CLEAR_ENTITY = 'DRAWER_CLEAR_ENTITY';
const DRAWER_PREVIOUS_STATE = 'DRAWER_PREVIOUS_STATE';

// actions
const actions = ({
  newEntity: (entity, parentId) => ({
    // entity and parentId only necessary if there are ambiguities or
    // creation of entities which have parents e.g. measurements, readings
    // Context allows the inference of most of cases
    type: DRAWER_NEW_ENTITY,
    payload: { entity, parentId }
  }),
  editEntity: (entityId, entity) => ({
    type: DRAWER_EDIT_ENTITY,
    payload: { entityId, entity }
  }),
  viewEntity: (entityId, entity) => ({
    type: DRAWER_VIEW_ENTITY,
    payload: { entityId, entity }
  }),
  clearEntity: () => ({
    type: DRAWER_CLEAR_ENTITY
  }),
  previousState: () => ({
    type: DRAWER_PREVIOUS_STATE
  })
});
export { actions };

// reducer
const INITIAL_STATE = {
  entity: null,
  entityId: null,
  context: null,
  previousState: []
};

let newPreviousState = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case DRAWER_NEW_ENTITY:
      newPreviousState = state.previousState;
      return {
        context: 'new',
        entity: action.payload.entity ? action.payload.entity : null,
        entityId: action.payload.parentId ? action.payload.parentId : null,
        previousState: [...newPreviousState, state]
      };
    case DRAWER_EDIT_ENTITY:
      newPreviousState = state.previousState;
      return { ...action.payload, context: 'edit', previousState: [...newPreviousState, state] };
    case DRAWER_VIEW_ENTITY:
      newPreviousState = state.previousState;
      return { ...action.payload, context: 'show', previousState: [...newPreviousState, state] };
    case DRAWER_PREVIOUS_STATE:
      const previousState = state.previousState.pop();
      return { ...previousState };
    case DRAWER_CLEAR_ENTITY:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

// sagas
