import FormViewer from 'Components/FormViewer';
import SimpleList from 'Components/SimpleList';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const Component = props => {
  const { device, indicator, measurement } = props;

  const properties = [
    { label: 'ID', content: () => measurement.id },
    { label: 'Name', content: model => model.name },
    { label: 'Activated At', content: model => unixToDate(model.created_at) },
    { label: 'Stopped At', content: model => model.stopped_at ? unixToDate(model.stopped_at) : '-' },
    { label: 'Indicator', content: () => indicator ? indicator.name : '-' },
    { label: 'Creation', content: model => `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Last Modification', content: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
  ];

  if (measurement.a_formula) {
    properties.splice(2, 0, { label: 'a_formula', content: model => model.a_formula });
  }

  if (device.in_protocol_name === 'Digital') {
    properties.splice(2, 0, { label: 'Port Name', content: model => model.port_name || '-' });
  }

  let attributes = properties.map(({ label, content, styleLabel }) => ({
    label, content: content(measurement), styleLabel
  }));

  attributes = [...attributes, ...displayMetadata(measurement.metadata_values)];

  return (
    <FormViewer label='Measurement Details'>
      <SimpleList content={attributes}/>
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const
    measurement = state.measurements.models[ownProps.measurementId],
    device = state.devices.models[measurement.device_id],
    indicator = state.indicators.models[measurement.indicator_id];

  return { device, indicator, measurement };
};

export default connect(mapStateToProps)(Component);

