import { call, delay, fork, put, race, take } from 'redux-saga/effects';
import columns from 'utils/columns/buildings';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { BUILDING, FETCH_BUILDINGS } from './actionTypes';

const START_POOLING_BUILDINGS = 'START_POOLING_BUILDINGS';
const STOP_POOLING_BUILDINGS = 'STOP_POOLING_BUILDINGS';

// actions
const actions = actionsFactory(BUILDING);

actions.startPoolingBuildings = () => ({
  type: START_POOLING_BUILDINGS
});

actions.stopPoolingBuildings = () => ({
  type: STOP_POOLING_BUILDINGS
});

export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(BUILDING);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(BUILDING, '/buildings', { searchInputDelay: 500 });

const POOL_INTERVAL = 60 * 1000;

function* poolBuildings() {
  try {
    while (true) {
      yield put({ type: FETCH_BUILDINGS });
      yield delay(POOL_INTERVAL);
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchBuildingsPooling() {
  try {
    while (true) {
      yield take(START_POOLING_BUILDINGS);
      yield race({
        task: call(poolBuildings),
        cancel: take(STOP_POOLING_BUILDINGS)
      });
    }
  } catch (error) {
    console.error(error);
  }
}

sagas.push(
  fork(watchBuildingsPooling)
);

export { sagas };
