import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';

const Toolbar = ({ currentTab, device, rootDevice }) => {
  const deviceTabs = [{ text: 'Details', id: 'details', url: `/devices/${device.id}/details` }];

  if (device.out_protocol_name === 'Acting Metal' || device.out_protocol_name === 'Reading Metal') {
    deviceTabs.push({ text: 'Measurements', id: 'measurements', url: `/devices/${device.id}/measurements` });
    if (device.out_protocol_name === 'Reading Metal') {
      deviceTabs.push({ text: 'Indicators', id: 'indicators', url: `/devices/${device.id}/indicators` });
    }
  } else if (device.out_protocol_name === 'Digital') {
    deviceTabs.push({ text: 'Measurements', id: 'measurements', url: `/devices/${device.id}/measurements` });
    deviceTabs.push({ text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` });
  } else {
    deviceTabs.push({ text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` });
  }

  if (device.out_protocol_name !== 'Acting Metal') {
    deviceTabs.push({ text: 'Data', id: 'data', url: `/devices/${device.id}/data` });
    if (rootDevice.new_wise_box) {
      deviceTabs.push({ text: 'Realtime', id: 'realtime', url: `/devices/${device.id}/realtime` });
    }
  }
  deviceTabs.push({ text: 'Logs', id: 'logs', url: `/devices/${device.id}/logs` });

  if (device.wise_box) {
    deviceTabs.push({ text: 'Accesses', id: 'accesses', url: `/devices/${device.id}/accesses` });
  }

  return (
    <TabsToolbar currentTab={currentTab} tabs={deviceTabs}/>
  );
};

export default Toolbar;
