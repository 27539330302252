import { sortBy } from 'lodash';
import { all, fork, put, take, takeLatest } from 'redux-saga/effects';
import { fetch } from 'utils/sagasHelpers';
import {
  BOOT,
  BOOTSTRAP,
  FETCH_BUILDINGS,
  FETCH_EQUIPMENT_CATEGORIES,
  FETCH_EQUIPMENTS,
  FETCH_METADATA_RULES,
  FETCH_METADATAS,
  FETCH_PROTOCOLS,
  FETCH_READINGS,
  FETCH_TEMPLATES,
  FETCH_USERS,
  FULFILLED
} from './actionTypes';

// reducer
export default (state = {}, { type, payload }) => {
  if (type === BOOTSTRAP + FULFILLED) {
    let data = { ...payload };
    data.entities = sortBy(data.entities, 'name');
    return data;
  }

  return state;
};

// sagas
function* watchBoot() {
  yield takeLatest(BOOT, boot);
}

function* boot() {
  try {
    yield put({ type: BOOTSTRAP });
    yield put({ type: FETCH_BUILDINGS });
    yield put({ type: FETCH_EQUIPMENT_CATEGORIES });
    yield put({ type: FETCH_EQUIPMENTS });
    yield put({ type: FETCH_METADATAS });
    yield put({ type: FETCH_METADATA_RULES });
    yield put({ type: FETCH_PROTOCOLS });
    yield put({ type: FETCH_READINGS });
    yield put({ type: FETCH_TEMPLATES });
    yield put({ type: FETCH_USERS });
    yield all([
      take(BOOTSTRAP + FULFILLED),
      take(FETCH_BUILDINGS + FULFILLED),
      take(FETCH_EQUIPMENT_CATEGORIES + FULFILLED),
      take(FETCH_EQUIPMENTS + FULFILLED),
      take(FETCH_METADATAS + FULFILLED),
      take(FETCH_METADATA_RULES + FULFILLED),
      take(FETCH_PROTOCOLS + FULFILLED),
      take(FETCH_READINGS + FULFILLED),
      take(FETCH_TEMPLATES + FULFILLED),
      take(FETCH_USERS + FULFILLED)
    ]);
    yield put({ type: BOOT + FULFILLED });
  } catch (error) {
    console.error(error);
  }
}

function* watchBootstrap() {
  yield takeLatest(BOOTSTRAP, fetch(BOOTSTRAP, '/bootstrap'));
}

const sagas = [
  fork(watchBoot),
  fork(watchBootstrap)
];

export { sagas };
