import Grid from '@material-ui/core/Grid';
import SpinButton from 'Components/Buttons/Button';
import BetterSelect from 'Components/Forms/BetterSelect';
import CheckBox from 'Components/Forms/CheckBox';
import DateTime from 'Components/Forms/DateTime';
import Text from 'Components/Forms/Input';
import TextArea from 'Components/Forms/InputArea';
import ItemGenerator from 'Components/Forms/ItemGenerator';
import Password from 'Components/Forms/Password';
import Select from 'Components/Forms/Select';
import SelectMultiple from 'Components/Forms/SelectMultiple';
import { initializeForm, initializeFormCleanUp } from 'ducks/formInitializer';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { formInitialDataExtractor } from 'utils/forms/utils';

const fieldTranslate = {
  'betterSelect': BetterSelect,
  'checkBox': CheckBox,
  'dateTime': DateTime,
  'itemGenerator': ItemGenerator,
  'password': Password,
  'select': Select,
  'selectMultiple': SelectMultiple,
  'text': Text,
  'textArea': TextArea
};

const Component = props => {
  const { formSchema, handleSubmit, formInitialValues, isSubmitting, loading = false, onSubmit } = props;
  const { initializeForm, initializeFormCleanUp } = props;

  useEffect(() => {
    return initializeFormCleanUp;
  }, []);

  useEffect(() => {
    if (formInitialValues) {
      initializeForm(formInitialDataExtractor(formInitialValues, formSchema));
    }
  }, [formInitialValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        {
          formSchema.map(field => {
              return (
                <Field
                  component={fieldTranslate[field.type]}
                  disabled={field.disabled}
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  normalize={field.normalize}
                  rows={field.rows}
                  schema={field}
                  validate={field.validate}
                />
              );
            }
          )
        }
      </Grid>
      <Grid container item xs={12} style={{ padding: '20px' }}>
        <Grid item>
          <SpinButton type='submit' label='Save' submitting={isSubmitting} loading={loading} size='small'/>
        </Grid>
      </Grid>
    </form>
  );
};

const BaseForm = reduxForm({
  form: 'form',
  enableReinitialize: true
})(Component);

const mapStateToProps = state => ({
  initialValues: state.formInitializers,
  metadataTypes: state.bootstrap.metadataTypes
});

const mapDispatchToProps = {
  initializeForm,
  initializeFormCleanUp
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseForm);

Component.propTypes = {
  formSchema: PropTypes.arrayOf(PropTypes.object).isRequired,
  // TODO: formValues being received as props, but BaseForm could be connected to the store
  formValues: PropTypes.object, // Object with values on form inputs
  handleSubmit: PropTypes.func, // From redux forms
  loading: PropTypes.bool,
  isSubmitting: PropTypes.bool, //alias, redux Forms use submitting (needs promise from onSubmit to work correctly)
  onSubmit: PropTypes.func
};
