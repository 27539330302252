import EntityView from 'Components/EntityView';
import React from 'react';
import { connect } from 'react-redux';

const properties = model => [
  { label: 'ID', content: model.id },
  { label: 'Name', content: model.name },
  { label: 'Email', content: model.email },
  { label: 'Enabled?', content: model.enabled ? 'Yes' : 'No' },
  { label: 'Config?', content: model.config ? 'Yes' : 'No' },
  { label: 'Daily Report?', content: model.report ? 'Yes' : 'No' }
];

const Component = props => (
  <EntityView
    disableDelete
    properties={properties}
    tabsHeader={'User details'}
    {...props}
  />
);

const mapStateToProps = state => ({
  loading: state.users.loading,
  model: state.users.models[state.drawerView.entityId]
});

export default connect(mapStateToProps)(Component);
