import { createSelector } from 'reselect';

const defaultValue = {};

const formValues = state => state.form && state.form.form && state.form.form.values || defaultValue;

const getFormValues = createSelector(
  formValues,
  formValues => formValues
);

export default {
  getFormValues
};
