export default [
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Name',
    dataKey: 'name'
  },
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Usable?',
    dataKey: 'usable',
    cellContentRenderer: usable => usable ? 'Yes' : 'No'
  }
];
