export default theme => ({
  itemGeneratorBox: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    border: `1px solid ${theme.palette.backgrounds.dark}`,
    marginTop: 16
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8
  },
  textField: {
    width: '100%'
  },
  sizeSmallFab: {
    width: 36,
    height: 36
  }
});