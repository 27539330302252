import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
  const { classes, content } = props;

  return content.map(({ label, content, styleLabel }, index) => (
    <Grid container key={index}>
      <Grid item xs={4}>
        <Typography className={classes.text} component='div' color='textPrimary' style={styleLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography className={classes.text} component='div' color='textPrimary'>
          {content}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
    </Grid>
  ));
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.node
      ]),
      styleLabel: PropTypes.object
    })
  ).isRequired
};
