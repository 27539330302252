import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import sortMeasurements from 'utils/sortMeasurements';

const Component = props => {
  const { device, devices, measurement, measurements, submitting } = props;
  const { closeDrawer, update } = props;

  const formSchema = [];
  const usedPortIds = [];
  Object.values(measurements).forEach(measurement => {
    if (measurement.port_id && devices[measurement.device_id].parent_id === device.parent_id) {
      usedPortIds.push(measurement.port_id);
    }
  });

  const availablePorts = Object.values(measurements).filter(measurement => measurement.device_id === device.parent_id && !usedPortIds.includes(measurement.id)).sort(sortMeasurements);

  formSchema.push({
    formData: availablePorts,
    label: 'Connected Port\x2a',
    name: 'port_id',
    type: 'select',
    validate: [validators.required]
  });

  const onSubmit = values => {
    const data = { ...serializeForm(values, formSchema, measurement), stopped_at: null };

    update(
      measurement.id,
      data,
      closeDrawer
    );
  };

  return (
    <FormViewer label='Restart Measurement'>
      <Form
        formSchema={formSchema}
        formInitialValues={measurement}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const
    measurement = state.measurements.models[ownProps.measurementId],
    device = state.devices.models[measurement.device_id];

  return {
    device,
    devices: state.devices.models,
    measurement,
    measurements: state.measurements.models,
    submitting: state.measurements.submitting
  };
};

const mapDispatchToProps = ({
  update: measurementActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
