import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest, uploadFiles } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { DELETE_FUTURE, DEVICE, FETCH_DEVICES, FETCH_MEASUREMENTS, FULFILLED, LOAD_BUILDING, REGENERATE_SEED_FILES, REJECTED } from './actionTypes';

const CREATE_DEVICE = 'CREATE_DEVICE';
const DELETE_DEVICE = 'DELETE_DEVICE';
const DEPLOY_NSI = 'DEPLOY_NSI';
const REPLACE_DEVICE = 'REPLACE_DEVICE';
const SYNC_WISE_BOX = 'SYNC_WISE_BOX';
const UPLOAD_CONFIG_FILE = 'UPLOAD_CONFIG_FILE';
const UPLOAD_FTTS = 'UPLOAD_FTTS';

// actions
const actions = actionsFactory(DEVICE);

actions.deployNsi = id => ({
  payload: id,
  type: DEPLOY_NSI
});

actions.deleteFuture = id => ({
  payload: id,
  type: DELETE_FUTURE
});

actions.regenerateSeedFiles = id => ({
  payload: id,
  type: REGENERATE_SEED_FILES
});

actions.replace = (id, equipmentId) => ({
  payload: { equipmentId, id },
  type: REPLACE_DEVICE
});

actions.syncWiseBox = id => ({
  payload: id,
  type: SYNC_WISE_BOX
});

actions.uploadConfigFile = (id, file) => ({
  payload: { id, file },
  type: UPLOAD_CONFIG_FILE
});

actions.uploadFtt = (id, files) => ({
  payload: { id, files },
  type: UPLOAD_FTTS
});

export { actions };

// reducer
const initialState = {
  ...initialStateFactory(),
  byParentId: {}
};

const reducer = reducerFactory(DEVICE);

let byParentId;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_DEVICE + FULFILLED:
      byParentId = { ...state.byParentId };

      // hammer for now - need to find a better solution
      Object.keys(byParentId).forEach(key => {
        byParentId[key] = byParentId[key].filter(id => id !== payload);
      });

      return reducer({ ...state, byParentId }, action);

    case CREATE_DEVICE + FULFILLED:
      const { id, parent_id } = payload;
      byParentId = { ...state.byParentId };

      byParentId[parent_id] = byParentId[parent_id] ? [...byParentId[parent_id], id] : [id];

      return reducer({ ...state, byParentId }, action);

    case FETCH_DEVICES + FULFILLED:
      byParentId = { ...state.byParentId };

      payload.forEach(model => {
        if (!byParentId[model.parent_id]) {
          byParentId[model.parent_id] = [];
        }

        if (!byParentId[model.parent_id].includes(model.id)) {
          byParentId[model.parent_id] = [...byParentId[model.parent_id], model.id];
        }
      });

      return reducer({ ...state, byParentId }, action);

    case REPLACE_DEVICE + FULFILLED:
      let models = { ...state.models, [payload.id]: payload };
      return reducer({ ...state, models, submitting: false }, action);

    case DELETE_FUTURE:
    case DEPLOY_NSI:
    case REGENERATE_SEED_FILES:
    case REPLACE_DEVICE:
    case SYNC_WISE_BOX:
    case UPLOAD_CONFIG_FILE:
    case UPLOAD_FTTS:
      return reducer({ ...state, submitting: true }, action);

    case DELETE_FUTURE + FULFILLED:
    case DELETE_FUTURE + REJECTED:
    case DEPLOY_NSI + FULFILLED:
    case DEPLOY_NSI + REJECTED:
    case REGENERATE_SEED_FILES + FULFILLED:
    case REGENERATE_SEED_FILES + REJECTED:
    case REPLACE_DEVICE + REJECTED:
    case SYNC_WISE_BOX + FULFILLED:
    case SYNC_WISE_BOX + REJECTED:
    case UPLOAD_CONFIG_FILE + FULFILLED:
    case UPLOAD_CONFIG_FILE + REJECTED:
    case UPLOAD_FTTS + FULFILLED:
    case UPLOAD_FTTS + REJECTED:
      return reducer({ ...state, submitting: false }, action);

    default:
      return reducer(state, action);
  }
};

// sagas
const sagas = sagasFactory(DEVICE, '/devices');

function* watchCreateDevice() {
  yield takeLatest(CREATE_DEVICE + FULFILLED, function* ({ payload }) {
    try {
      yield put({ type: FETCH_MEASUREMENTS, payload: { device_id: payload.id } });
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchDeleteFuture() {
  yield takeLatest(DELETE_FUTURE, function* ({ payload: id }) {
    try {
      yield apiRequest(`/devices/${id}/future_data`, 'DELETE');
      yield put({ type: DELETE_FUTURE + FULFILLED });
    } catch (error) {
      const errorObject = handleError(DELETE_FUTURE, error);
      yield put(errorObject);
    }
  });
}

function* watchDeployNsi() {
  yield takeLatest(DEPLOY_NSI, function* ({ payload: id }) {
    try {
      yield apiRequest(`/devices/${id}/deploy_nsi`, 'POST');
      yield put({ type: DEPLOY_NSI + FULFILLED });
    } catch (error) {
      const errorObject = handleError(DEPLOY_NSI, error);
      yield put(errorObject);
    }
  });
}

function* watchRegenerateSeedFiles() {
  yield takeLatest(REGENERATE_SEED_FILES, function* ({ payload: id }) {
    try {
      yield apiRequest(`/devices/${id}/regenerate_seed_files`, 'POST');
      yield put({ type: REGENERATE_SEED_FILES + FULFILLED });
    } catch (error) {
      const errorObject = handleError(REGENERATE_SEED_FILES, error);
      yield put(errorObject);
    }
  });
}

function* watchReplaceDevice() {
  yield takeLatest(REPLACE_DEVICE, function* ({ payload }) {
    try {
      let data = {};
      if (payload.equipmentId) {
        data.equipment_id = payload.equipmentId;
      }
      let response = yield apiRequest(`/devices/${payload.id}/replace`, 'PUT', data);
      yield put({ type: REPLACE_DEVICE + FULFILLED, payload: response.data });
      if (!isEmpty(data)) {
        yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
      }
    } catch (error) {
      const errorObject = handleError(REPLACE_DEVICE, error);
      yield put(errorObject);
    }
  });
}

function* watchSyncConfig() {
  yield takeLatest(SYNC_WISE_BOX, function* ({ payload: id }) {
    try {
      let { data: device } = yield apiRequest(`/devices/${id}/sync_configuration`, 'POST');
      yield put({ type: SYNC_WISE_BOX + FULFILLED });
      yield put({ type: 'FETCH_DEVICE' + FULFILLED, payload: device });
    } catch (error) {
      const errorObject = handleError(SYNC_WISE_BOX, error);
      yield put(errorObject);
    }
  });
}

function* watchUploadConfigFile() {
  yield takeLatest(UPLOAD_CONFIG_FILE, function* ({ payload }) {
    const { file, id } = payload;
    try {
      yield uploadFiles(`/devices/${id}/file`, 'PUT', file);
      yield put({ type: UPLOAD_CONFIG_FILE + FULFILLED });
    } catch (error) {
      const errorObject = handleError(UPLOAD_CONFIG_FILE, error);
      yield put(errorObject);
    }
  });
}

function* watchUploadFtts() {
  yield takeLatest(UPLOAD_FTTS, function* ({ payload }) {
    const { files, id } = payload;
    try {
      yield uploadFiles(`/devices/${id}/ftt`, 'PUT', files);
      yield put({ type: UPLOAD_FTTS + FULFILLED });
    } catch (error) {
      const errorObject = handleError(UPLOAD_FTTS, error);
      yield put(errorObject);
    }
  });
}

sagas.push(
  fork(watchCreateDevice),
  fork(watchDeployNsi),
  fork(watchDeleteFuture),
  fork(watchRegenerateSeedFiles),
  fork(watchReplaceDevice),
  fork(watchSyncConfig),
  fork(watchUploadConfigFile),
  fork(watchUploadFtts)
);

export { sagas };
