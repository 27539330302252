import FormViewer from 'Components/FormViewer';
import SimpleList from 'Components/SimpleList';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const baseProperties = [
  { label: 'ID', content: model => model.id },
  { label: 'Name', content: model => model.name },
  { label: 'Circuit Name', content: model => model.circuit_name },
  { label: 'Creation', content: model => `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
  { label: 'Update', content: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {
  let attributes = baseProperties.map(({ label, content, styleLabel }) => ({
    label, content: content(props.measurement), styleLabel
  }));

  attributes = [...attributes, ...displayMetadata(props.measurement.metadata_values)];

  return (
    <FormViewer label='Measurement Details'>
      <SimpleList content={attributes}/>
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  measurement: state.measurements.models[ownProps.id]
});

export default connect(mapStateToProps)(Component);
