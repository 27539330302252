import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationBox from 'Components/ConfirmationBox';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './styles';

const Component = props => {
  const {
    classes, confirmationText, confirmationHeader = 'Please Confirm', submitting = false,
    color, disabled, icon, spin, size = 'sm', tooltip
  } = props;
  const { onClick } = props;

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const openDialog = () => setOpenConfirmation(true);
  const closeDialog = () => setOpenConfirmation(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
      if (isSubmitting && !submitting) {
        setIsSubmitting(false);
        closeDialog();
      } else if (!isSubmitting && submitting) {
        setIsSubmitting(true);
      }
    }, [submitting]
  );

  const LocalIcon = (
    <IconButton
      className={size === 'sm' ? classes.smallButtons : null}
      disabled={disabled}
      onClick={confirmationText ? openDialog : onClick}
    >
      <Icon icon={icon} size='xs' spin={spin} fixedWidth style={{ color: color ? color : null }}/>
    </IconButton>
  );

  let confirmation;
  if (confirmationText) {
    confirmation = (
      <ConfirmationBox
        alert={confirmationText}
        alertHeader={confirmationHeader}
        open={openConfirmation}
        onConfirm={onClick}
        onNo={closeDialog}
        submitting={submitting}
      />
    );
  }

  return (
    <React.Fragment>
      {confirmation}
      {tooltip && !disabled ? <Tooltip title={tooltip}>{LocalIcon}</Tooltip> : LocalIcon}
    </React.Fragment>
  );
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.arrayOf(PropTypes.string),
  confirmationHeader: PropTypes.string,
  confirmationText: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  spin: PropTypes.bool,
  tooltip: PropTypes.string
};
