import { blue as blueMui, green, orange, red } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import merge from 'lodash/merge';
import React from 'react';

// TODO: Overall theme should be refactored so stylings start including theme.palette.augmentColor.
// https://github.com/mui-org/material-ui/issues/12390

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#8BC53F',
      main: '#224C4F',
      dark: '#1A1D24',
      white: '#EEEEEE'
    },
    secondary: {
      light: '#A2D0FF',
      main: '#8BC53F',
      dark: '#50B87F',
      white: '#EEEEEE'
    },
    pins: {
      down: '#FF5F60',
      ignore: '#6EB3E3',
      ok: '#8BC53F',
      problem: '#F59E24',
      unknown: '#AAAAAA'
    },
    type: 'light',
    background: {
      default: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: ['Gotham', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif'],
    useNextVariants: true
  }
});

const blue = { main: blueMui['600'] };
rawTheme.palette.augmentColor(blue);

const ok = { main: fade(green['500'], 0.75) };
rawTheme.palette.augmentColor(ok);

const warning = { main: fade(orange['500'], 0.75) };
rawTheme.palette.augmentColor(warning);

const down = { main: fade(red['500'], 0.75) };
rawTheme.palette.augmentColor(down);

const backgrounds = {
  main: fade(rawTheme.palette.primary.main, 0.2),
  dark: fade(rawTheme.palette.primary.main, 0.7)
};
rawTheme.palette.augmentColor(backgrounds);

const theme = merge(rawTheme, {
  palette: { blue, down, ok, warning, backgrounds }
});

export default theme;
