import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandedPanel from 'Components/ExpandedPanel';
import Loader from 'Components/ScopedLoader';
import SimpleList from 'Components/SimpleList';
import TabsToolbar from 'Components/TabsToolbar';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
  const { classes, loading, panelHeader, properties, tabsHeader } = props;
  const { onBack, onDelete, onEdit } = props;

  const buttons = [];

  if (onBack) {
    buttons.push({ icon: icons.back, onClick: () => onBack(), tooltip: 'Back' });
  }

  if (onEdit) {
    buttons.push({ icon: icons.editConfig, onClick: () => onEdit(), tooltip: 'Edit' });
  }

  if (onDelete) {
    buttons.push({
      icon: icons.trash,
      confirmationHeader: 'Delete Confirmation',
      confirmationText: 'Are you sure you want to delete this entity?',
      submitting: loading,
      onClick: () => onDelete(),
      tooltip: 'Delete'
    });
  }

  let Content = (
    <Grid item xs={12} className={classes.bottomContainer}>
      <ExpandedPanel header={panelHeader} loading={loading}>
        <SimpleList content={properties}/>
      </ExpandedPanel>
    </Grid>
  );
  if (loading) {
    Content = (
      <Loader/>
    );
  }

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Grid container item xs={12} className={classes.headerContainer}>
        <TabsToolbar
          buttons={buttons}
          tabs={[{ text: tabsHeader, id: 'none' }]}
        />
      </Grid>
      {Content}
    </Grid>
  );
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  panelHeader: PropTypes.string,
  properties: PropTypes.array,
  tabsHeader: PropTypes.string
};
