export default theme => ({
  root: {
    color: theme.palette.background.default,
    fontWeight: 'bold'
  },
  tooltip: {
    margin: 2,
    width: '100%'
  },
  popover: {
    marginBottom: 5
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit,
    overflowX: 'auto'
  },
  tableGlobal: {
    minWidth: 150
  },
  tableRow: {
    height: 38
  },
  tableCell: {
    fontWeight: 'bold'
  }
});
