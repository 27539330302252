import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
  const { activeDeviceId, classes, getPrimaryText, getSecondaryText, indexedNodes, nodeId, nodes, openNodes, selectNode, sorter, toggleNode } = props,
    node = nodes[nodeId], depth = props.depth || 0;

  if (!node) {
    return null;
  }

  const handleSelect = event => selectNode(event.currentTarget.dataset.id);
  const handleToggle = event => toggleNode(event.currentTarget.dataset.id);
  const children = indexedNodes[nodeId];

  let Icon;
  if (children) {
    Icon = (
      <IconButton onClick={handleToggle} data-id={nodeId} className={classes.iconButton}>
        <FontAwesomeIcon fixedWidth icon={icons[openNodes.includes(nodeId) ? 'closeNode' : 'openNode']} size='xs'/>
      </IconButton>
    );
  }

  let Children;
  if (children) {
    Children = (
      <Collapse in={openNodes.includes(nodeId)} timeout='auto' unmountOnExit>
        <List disablePadding dense component='div'>
          {sorter(children, nodes).map(id => <Component {...props} key={id} depth={depth + 1} nodeId={id}/>)}
        </List>
      </Collapse>
    );
  }

  const style = {
    backgroundColor: nodeId === activeDeviceId ? '#DDDDDD' : null,
    paddingLeft: depth * 12 + (children ? 0 : 43)
  };

  return (
    <React.Fragment key={nodeId}>
      <ListItem
        button
        className={classes.listItem}
        disableGutters selected={nodeId === activeDeviceId}
        style={style}
      >
        {Icon}
        <ListItemText
          className={classes.listItemText}
          data-id={nodeId}
          onClick={handleSelect}
          primary={getPrimaryText(node)}
          secondary={getSecondaryText(node)}
        />
      </ListItem>
      {Children}
    </React.Fragment>
  );
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  indexedNodes: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired,
  openNodes: PropTypes.array.isRequired
};
