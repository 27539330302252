import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions } from 'ducks/deviceLogs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import statusToColor from 'utils/statusToColor';
import icons from 'utils/ui/icons';
import Add from './Add';

const statusToComponent = model => {
  const description = model.description.split('\n').map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br/>
      </React.Fragment>
    );
  });

  if (model.type === 'status') {
    return (
      <span style={{ color: statusToColor(model.description.toLowerCase().replace('status changed to ', '')) }}>
        {description}
      </span>
    );
  }

  return description;
};

const columns = [
  { label: 'Type', dataKey: 'type' },
  { label: 'Date', dataKey: 'created_at', transform: model => unixToDate(model.created_at) },
  { label: 'Description', dataKey: 'description', transform: statusToComponent }
];

const Component = props => {
  const { device, loading, logs } = props;
  const { clear, fetchAll } = props;

  useEffect(() => {
    fetchAll({ device_id: device.id });
    return () => clear();
  }, []);

  const [drawer, setOpenDrawer] = useState(null);
  const closeDrawer = () => setOpenDrawer(null);

  let buttons = [
    {
      icon: icons.add,
      onClick: () => setOpenDrawer('addEntry'),
      tooltip: 'Add Log Entry'
    }
  ];

  let DrawerContent;
  if (drawer === 'addEntry') {
    DrawerContent = (
      <Add device={device} onSave={closeDrawer}/>
    );
  }

  return (
    <React.Fragment>
      <Panel buttons={buttons}>
        <Table columns={columns} loading={loading} rows={logs}/>
      </Panel>
      <Drawer onClose={closeDrawer} open={!!drawer}>
        {DrawerContent}
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loading: state.deviceLogs.loading,
  logs: state.deviceLogs.data
});

const mapDispatchToProps = ({
  clear: actions.clear,
  fetchAll: actions.fetchAll
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
