import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Icon from 'Components/Icon';
import React from 'react';
import styles from './styles';

const Component = props => {
  const
    { count, page, rowsPerPage, onChangePage, classes } = props,
    handleBackButtonClick = () => onChangePage(page - 1),
    handleFirstPageButtonClick = () => onChangePage(0),
    handleLastPageButtonClick = () => onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1)),
    handleNextButtonClick = () => onChangePage(page + 1);

  return (
    <div className={classes.root}>
      <IconButton disabled={page === 0} onClick={handleFirstPageButtonClick}>
        <Icon icon={['fas', 'arrow-to-left']} size='xs'/>
      </IconButton>
      <IconButton disabled={page === 0} onClick={handleBackButtonClick}>
        <Icon icon={['fas', 'chevron-left']} size='xs'/>
      </IconButton>
      <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1} onClick={handleNextButtonClick}>
        <Icon icon={['fas', 'chevron-right']} size='xs'/>
      </IconButton>
      <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1} onClick={handleLastPageButtonClick}>
        <Icon icon={['fas', 'arrow-to-right']} size='xs'/>
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Component);
