import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect } from 'react';
import styles from './styles';
import Table from './Table';
import Toolbar from './Toolbar';

const Component = props => {
  const {
    allChecked,
    checked,
    classes,
    changeSearch,
    changeSort,
    columns,
    fetchData,
    ids,
    loaded,
    loading,
    models,
    onRowClick,
    searching,
    searchValue,
    sortBy,
    sortDirection,
    toggleAll,
    toggleOne,
    TableActions
  } = props;

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container>
        <Toolbar
          classes={classes}
          changeSearch={changeSearch}
          ids={ids}
          loading={loading}
          models={models}
          searching={searching}
          searchValue={searchValue}
          TableActions={TableActions}
        />
      </Grid>
      <Grid className={classes.table} item xs={12}>
        <Table
          allChecked={allChecked}
          checked={checked}
          columns={columns}
          models={models}
          ids={ids}
          onRowClick={onRowClick}
          onSort={changeSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleAll={toggleAll}
          toggleOne={toggleOne}
        />
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(Component);
