import { BOOT, COLLAPSE_TREE, EXPAND_TREE, FULFILLED, TOGGLE_BUILDINGS, TOGGLE_MENU, TOGGLE_MODAL, TOGGLE_NODE, TOGGLE_PROBLEMS, TOGGLE_SIDEBAR } from './actionTypes';

// actions
export const closeMenu = () => ({
  type: TOGGLE_MENU,
  payload: { state: false }
});

export const closeSidebar = () => ({
  type: TOGGLE_SIDEBAR,
  payload: { state: false }
});

export const collapseTree = () => ({
  type: COLLAPSE_TREE
});

export const expandTree = (id, devices) => ({
  payload: { devices, id },
  type: EXPAND_TREE
});

export const openMenu = () => ({
  type: TOGGLE_MENU,
  payload: { state: true }
});

export const openSidebar = () => ({
  type: TOGGLE_SIDEBAR,
  payload: { state: true }
});

export const toggleBuildings = () => ({
  type: TOGGLE_BUILDINGS
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL
});

export const toggleNode = id => ({
  payload: id,
  type: TOGGLE_NODE
});

export const toggleProblems = (buildingId, devices) => ({
  payload: { buildingId, devices },
  type: TOGGLE_PROBLEMS
});

// reducer
const INITIAL_STATE = {
  booting: false,
  openNodes: [],
  showBuildings: false,
  showMenu: false,
  showModal: false,
  showSidebar: false
};

let openNodes;

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case BOOT:
      return { ...state, booting: true };

    case BOOT + FULFILLED:
      return { ...state, booting: false };

    case EXPAND_TREE:
      openNodes = [];
      let currentNode = payload.devices[payload.id];
      while (currentNode.parent_id) {
        openNodes.push(currentNode.parent_id);
        currentNode = payload.devices[currentNode.parent_id];
      }
      return { ...state, openNodes };

    case COLLAPSE_TREE:
      return { ...state, openNodes: [] };

    case TOGGLE_BUILDINGS:
      return { ...state, showBuildings: !state.showBuildings };

    case TOGGLE_MENU:
      return { ...state, showMenu: payload.state };

    case TOGGLE_MODAL:
      return { ...state, showModal: !state.showModal };

    case TOGGLE_NODE:
      return {
        ...state,
        openNodes: state.openNodes.includes(payload) ? state.openNodes.filter(node => node !== payload) : [...state.openNodes, payload]
      };

    case TOGGLE_SIDEBAR:
      return { ...state, showSidebar: payload.state };

    case TOGGLE_PROBLEMS:
      openNodes = Object.values(payload.devices).reduce((acc, device) => {
        if (device.building_id === payload.buildingId && device.status === 'problem') {
          acc.push(device.id);
        }

        return acc;
      }, []);
      return { ...state, openNodes };

    default:
      return state;
  }
};
