import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import Default from './Default';
import styles from './styles';
import WiseBox from './WiseBox';

const Component = props => {
  const { device, ...rest } = props;

  if (device.new_wise_box) {
    return (
      <WiseBox {...rest}/>
    );
  } else {
    return (
      <Default {...rest}/>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  device: state.devices.models[ownProps.deviceId]
});

export default connect(mapStateToProps)(withStyles(styles)(Component));
