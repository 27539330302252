import { keyBy } from 'lodash';
import validators from 'utils/forms/validators';

const serializeForm = (formValues, fields, initialData = {}) => {
  let dataToSubmit = {};

  fields.forEach(field => {
    let value = formValues[field.name];
    if (field.transformation) {
      value = field.transformation(value);
    }

    if (!field.metadata) {
      if (formValues[field.name] !== initialData[field.name]) {
        dataToSubmit[field.name] = value;
      }
      return;
    }

    if (!dataToSubmit.metadata_values) {
      dataToSubmit.metadata_values = [];
    }

    const currentMetadata = (initialData.metadata_values || []).find(mv => mv.metadata_id === field.metadata.id);

    if (currentMetadata) {
      if (formValues[field.name] !== currentMetadata.value) {
        dataToSubmit.metadata_values.push({
          id: currentMetadata.id,
          value: formValues[field.name]
        });
      }
    } else {
      dataToSubmit.metadata_values.push({
        metadata_id: field.metadata.id,
        metadata_rule_id: field.metadata.metadata_rule_id,
        value: field.metadata.metadata_type_id === 3 ? !!formValues[field.name] : formValues[field.name]
      });
    }
  });

  return dataToSubmit;
};

const formInitialDataExtractor = (model, fields) => {
  const initialValues = {};
  fields.forEach(field => {
    if (field.metadata) {
      const metadata = model.metadata_values.find(item => item.metadata_id === field.metadata.id);
      if (metadata) {
        initialValues[field.name] = metadata.value;
      }
    } else {
      initialValues[field.name] = model[field.name];
    }
  });
  return initialValues;
};

const metadataFields = (metadataList = [], model = {}) => {
  const
    metadataValues = keyBy(model.metadata_values, 'metadata_id'),
    usableMetadata = [];

  metadataList.forEach(metadata => {
    const metadataValue = metadataValues[metadata.id];
    if (!metadataValue || metadata.modifiable) {
      usableMetadata.push(metadata);
    }
  });

  return usableMetadata.map(metadata => {
    let componentRenderer = 'text', formData;

    if (metadata.metadata_type_id === 3) {
      componentRenderer = 'checkBox';
    } else if (metadata.metadata_type_id === 4) {
      componentRenderer = 'select';
      formData = metadata.enumerator_members.map(member => ({ id: member, name: member }));
    }

    let field = {
      label: `${metadata.name}\x2a`,
      metadata: metadata,
      name: `metadata_${metadata.id}`,
      type: componentRenderer
    };

    if (formData) {
      field.formData = formData;
    }

    if (metadata.metadata_type_id !== 3) {
      field.validate = [validators.required];
    }

    return field;
  });
};

export {
  formInitialDataExtractor,
  serializeForm,
  metadataFields
};
