import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from 'Components/Header';
import Loader from 'Components/Loader';
import UserFeedback from 'Components/UserFeedback';
import LoginPage from 'Pages/LoginPage';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import icons from 'utils/ui/icons';
import history from './history';
import Routes from './Routes';

const styles = () => ({
  container: {
    bottom: 0,
    left: 0,
    position: 'relative',
    right: 0,
    top: 64,
    height: 'calc(100vh - 64px)'
  }
});

const generateSections = user => {
  const sections = [
    {
      name: 'Buildings',
      subsections: [
        { text: 'Map', icon: icons.map, url: '/map' },
        { text: 'Table', icon: icons.table, url: '/buildings' }
      ]
    },
    {
      name: 'Catalog',
      subsections: [
        { text: 'Equipment', icon: icons.equipment, url: '/equipment' },
        { text: 'Metadata Rules', icon: icons.metadataRule, url: '/metadata_rules' }
      ]
    }
  ];

  if (user.superuser) {
    sections.push(
      {
        name: 'WiseBoxes',
        subsections: [
          { text: 'Templates', icon: icons.template, url: '/templates' },
          { text: 'Unclaimed', icon: icons.unclaimed, url: '/unclaimed' }
        ]
      }
    );
  }

  if (user.root) {
    sections.push(
      {
        name: 'Entities',
        subsections: [
          { text: 'Equipment Categories', icon: icons.equipmentCategories, url: '/equipment_categories' },
          { text: 'Metadata', icon: icons.metadata, url: '/metadatas' },
          { text: 'Protocols', icon: icons.protocol, url: '/protocols' }
        ]
      },
      {
        name: 'Accesses',
        subsections: [
          { text: 'Users', icon: icons.users, url: '/users' }
        ]
      }
    );
  }

  return sections;
};

const App = ({ booting, classes, user = {} }) => {
  if (booting) {
    return (
      <Loader/>
    );
  }

  const sections = generateSections(user);

  return (
    <React.Fragment>
      <CssBaseline/>
      <UserFeedback/>
      <Router history={history}>
        <Switch>
          <Route path='/login' component={LoginPage}/>
          <React.Fragment>
            <Header sections={sections}/>
            <Grid className={classes.container} container spacing={0}>
              <Routes/>
            </Grid>
          </React.Fragment>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  booting: state.ui.booting,
  user: state.users.models[state.auth.currentUserId]
});

export default connect(mapStateToProps)(withStyles(styles)(App));
