import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

const equipments = state => state.equipments.models;
const devices = state => state.devices.models;
const protocols = state => state.protocols.models;
const parentDeviceId = (state, ownProps) => ownProps.parentDeviceId || 'null';

export const equipmentsByInProtocolId = createSelector(
  equipments,
  devices,
  protocols,
  parentDeviceId,
  (equipments, devices, protocols, parentDeviceId) => {
    const parentDevice = devices[parentDeviceId];

    let list = [];
    if (parentDeviceId === 'null') {
      const protocol = Object.values(protocols).find(protocol => protocol.name.toLowerCase() === 'root');
      list = Object.values(equipments).filter(equipment => equipment.in_protocol_id === protocol.id);
    } else {
      list = Object.values(equipments).filter(equipment =>
        !equipment.nsi &&
        !equipment.wise_box &&
        parentDevice.out_protocol_id === equipment.in_protocol_id
      );
    }

    return sortBy(list, 'name');
  }
);

export default {
  equipmentsByInProtocolId
};
