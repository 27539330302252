import { Avatar, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Menu from 'Components/Header/Menu';
import { openMenu } from 'ducks/ui';
import React from 'react';
import { connect } from 'react-redux';
import getInitials from 'utils/getInitials';
import styles from './styles';

const Component = props => {
  const { classes, currentUser = {}, showMenu } = props;
  const { openMenu } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const displayMenu = event => {
    setAnchorEl(event.currentTarget);
    openMenu();
  };

  return (
    <div className={classes.sectionDesktop}>
      <IconButton className={classes.avatar} onClick={displayMenu} onMouseEnter={displayMenu}>
        <Avatar className={showMenu ? classes.avatarTextActive : classes.avatarText}>{getInitials(currentUser.name)}</Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl}/>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.users.models[state.auth.currentUserId],
  showMenu: state.ui.showMenu
});

const mapDispatchToProps = ({ openMenu });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
