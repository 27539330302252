const generateSvg = color => `
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker"
     role="img" style="zoom: 1;" width="384" height="512">
    <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none"/>
    <g class="currentLayer" style="">
        <title>Layer 1</title>
        <path d="M173.40127858551367,491.74601300856796 C37.09645989302237,294.1444598344562 11.795762897692157,273.8644898109716 11.795762897692157,201.24295561309054 C11.795762897692157,101.76721466150514 92.43622525421222,21.126752304985075 191.91196620579763,21.126752304985075 s180.11620330810547,80.64046329462528 180.11620330810547,180.11620330810547 c0,72.62153982651233 -25.30069793343544,92.90149859273434 -161.60551099729537,290.5030452001095 c-8.944833325743675,12.921461376905443 -28.077489401102067,12.920523271679878 -37.02138462162018,0 z"
              fill="${color}" id="svg_1" class="selected" stroke-opacity="1" stroke="#484848" stroke-width="20"
              fill-opacity="1" opacity="1"/>
    </g>
  </svg>
`;

export default color => {
  const svg = generateSvg(color);

  return 'data:image/svg+xml;base64,' + window.btoa(svg);
};
