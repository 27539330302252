import React from 'react';
import statusToColor from 'utils/statusToColor';

export default [
  {
    width: 60,
    flexGrow: 0.8,
    label: 'Name',
    dataKey: 'name'
  },
  {
    width: 60,
    flexGrow: 0.8,
    label: 'Organization',
    dataKey: 'organization_name',
    disableSort: true
  },
  {
    width: 60,
    flexGrow: 0.2,
    label: 'Status',
    dataKey: 'status',
    cellContentRenderer: status => <span style={{ color: statusToColor(status) }}>{status}</span>
  },
  {
    width: 140,
    flexGrow: 1.0,
    label: 'Devices',
    dataKey: 'devices',
    cellContentRenderer: devices => devices.map(device => `${device.name} (${device.status})`)
  }
];
