import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipment';
import React from 'react';
import { connect } from 'react-redux';
import selectorEquipmentCategories from 'selectors/equipmentCategories';
import selectorForm from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import selectorProtocols from 'selectors/protocols';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
  const { equipmentCategoriesArray, metadatas, protocolsArray } = props;

  const formSchema = [
    {
      type: 'textArea',
      label: 'Name\x2a',
      name: 'name',
      validate: [validators.required]
    },
    {
      type: 'select',
      label: 'Equipment Category\x2a',
      name: 'equipment_category_id',
      validate: [validators.required],
      formData: equipmentCategoriesArray.filter(category => category.usable)
    },
    {
      type: 'select',
      label: 'Input protocol\x2a',
      name: 'in_protocol_id',
      validate: [validators.required],
      formData: protocolsArray
    },
    {
      type: 'select',
      label: 'Output protocol\x2a',
      name: 'out_protocol_id',
      validate: [validators.required],
      formData: protocolsArray
    },
    ...metadataFields(metadatas)
  ];

  return (
    <EntityManageView
      formSchema={formSchema}
      label='New Equipment'
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  equipmentCategoriesArray: selectorEquipmentCategories.equipmentCategoriesArray(state),
  formValues: selectorForm.getFormValues(state),
  metadatas: selectorMetadatas.metadataFromEquipment(state),
  protocolsArray: selectorProtocols.protocolsArray(state),
  protocolsOut: selectorProtocols.formProtocolsOutFromProtocolsIn(state)
});

const mapDispatchToProps = ({
  create: actions.create,
  update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
