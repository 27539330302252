import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import styles from './styles';

const Component = props => {
  const { classes, columns, ids, models, ...tableProps } = props;
  const { rowClassName, onRowClick, rowHeight, headerHeight, checked, allChecked, onSort } = props;

  const rowGetter = ({ index }) => models[ids[index]];

  const getRowClassName = () => (
    classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.noClick]: !onRowClick
    })
  );

  const checkboxRenderer = id => (
    <TableCell
      component='div'
      className={classNames(classes.flexContainer, classes.checkboxCell, {
        [classes.tableHeader]: !id
      })}
      style={{ height: id ? rowHeight : headerHeight }}
      variant={id ? 'body' : 'head'}
    >
      <Checkbox
        checked={allChecked || checked.has(String(id))}
        name={String(id)}
        onChange={props[id ? 'toggleOne' : 'toggleAll']}
      />
    </TableCell>
  );

  const cellRenderer = ({ cellData, dataKey, rowData }) => {
    if (dataKey === 'checkbox') {
      return checkboxRenderer(rowData.id);
    }

    return (
      <TableCell
        className={classNames(classes.flexContainer, {
          [classes.noClick]: !onRowClick
        })}
        component='div'
        data-id={rowData.id}
        onClick={onRowClick}
        style={{ height: rowHeight }}
        variant='body'
      >
        {
          cellData instanceof Array ?
            cellData.join(', ') :
            cellData
        }
      </TableCell>
    );
  };

  const headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    if (dataKey === 'checkbox') {
      return checkboxRenderer();
    }

    const { headerHeight, columns, onSort } = props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc'
    };

    let inner = label;

    const sortable = !columns[columnIndex].disableSort && onSort != null;
    if (sortable) {
      inner = (
        <TableSortLabel active={dataKey === sortBy} className={classes.sortLabel} direction={direction[sortDirection]}>
          {label}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        className={classNames(classes.flexContainer, classes.tableHeader, { [classes.noClick]: !sortable })}
        component='div'
        style={{ height: headerHeight }}
        variant='head'
      >
        {inner}
      </TableCell>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          {...tableProps}
          rowCount={ids.length}
          rowGetter={rowGetter}
          sort={({ sortBy, sortDirection }) => {
            if (sortBy !== 'checkbox') {
              return onSort({ sortBy, sortDirection });
            }
          }}
          className={classes.table}
          height={height}
          width={width}
          rowClassName={getRowClassName}
          onRowClick={null}
        >
          {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
            let renderer;
            if (cellContentRenderer != null) {
              renderer = cellRendererProps => cellRenderer({
                ...cellRendererProps,
                cellData: cellContentRenderer(cellRendererProps.cellData)
              });
            } else {
              renderer = cellRenderer;
            }

            return (
              <Column
                key={dataKey}
                headerRenderer={headerProps =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index
                  })
                }
                className={classNames(classes.flexContainer, className)}
                cellRenderer={renderer}
                dataKey={dataKey}
                {...other}
              />
            );
          })}
        </Table>
      )}
    </AutoSizer>
  );
};

Component.propTypes = {
  allChecked: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func
};

Component.defaultProps = {
  headerHeight: 48,
  rowHeight: 40
};

export default withStyles(styles)(Component);
