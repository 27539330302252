import FormViewer from 'Components/FormViewer';
import React from 'react';
import { connect } from 'react-redux';
import globals from '~/globals';

const Component = props => {
  const { template } = props;

  return (
    <FormViewer label='Template Details'>
      <a target="_blank" href={`${globals.apiUrl}/installation_templates/${template.id}/report`}>Download File</a>
      <br/>
      <pre>{JSON.stringify(template.data, null, 2)}</pre>
    </FormViewer>
  );
};

const mapStateToProps = state => {
  return {
    template: state.templates.models[state.drawerView.entityId]
  };
};

export default connect(mapStateToProps)(Component);
