import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';

const Toolbar = ({ currentTab, device }) => {
  const deviceTabs = [
    { text: 'Details', id: 'details', url: `/devices/${device.id}/details` },
    { text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` },
    { text: 'Data', id: 'data', url: `/devices/${device.id}/data` },
    { text: 'Logs', id: 'logs', url: `/devices/${device.id}/logs` }
  ];

  if (device.new_wise_box) {
    deviceTabs.splice(3, 0, { text: 'Realtime', id: 'realtime', url: `/devices/${device.id}/realtime` });
  }

  return (
    <TabsToolbar currentTab={currentTab} tabs={deviceTabs}/>
  );
};

export default Toolbar;
