import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => {
  const render = props => {
    if (currentUser) {
      return <Component {...props} />;
    }

    return (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    );
  };

  return (
    <Route {...rest} render={render}/>
  );
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUserId
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
