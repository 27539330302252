import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as templateActions } from 'ducks/templates';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import history from '~/history';

const Component = props => {
  const { device, submitting } = props;
  const { closeDrawer, create } = props;

  let formSchema = [
    {
      type: 'text',
      label: 'Name\x2a',
      name: 'name',
      validate: [validators.required]
    }
  ];

  const onSubmit = values => {
    const data = serializeForm(values, formSchema);
    data.wise_box_id = device.id;

    create(data, () => {
      closeDrawer();
      history.push('/templates');
    });
  };

  return (
    <FormViewer label='Create Template'>
      <Form
        formSchema={formSchema}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  device: state.devices.models[ownProps.id],
  submitting: state.templates.submitting
});

const mapDispatchToProps = ({
  create: templateActions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
