import React from 'react';

export default [
  {
    width: 100,
    flexGrow: 0.5,
    label: 'Equipment category',
    dataKey: 'equipment_category_name'
  },
  {
    width: 75,
    flexGrow: 0.5,
    label: 'Entity',
    dataKey: 'entity_name'
  },
  {
    cellContentRenderer: modifiable => modifiable ? 'Yes' : 'No',
    dataKey: 'modifiable',
    flexGrow: 0.5,
    label: 'Modifiable?',
    width: 75
  },
  {
    width: 400,
    flexGrow: 2.0,
    label: 'Metadata',
    dataKey: 'metadata_names'
  }
];
