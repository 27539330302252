import Input from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const SearchInput = ({ classes, loading, onSearchChange, searching, searchValue }) => {
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <Icon icon={(loading || searching) ? icons.spinner : icons.search} spin={loading || searching}/>
      </div>
      <Input
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        disabled={loading}
        disableUnderline
        placeholder={loading ? 'Loading data…' : 'Search…'}
        onChange={event => {
          onSearchChange(event.target.value);
        }}
        value={searchValue}
      />
    </div>
  );
};

export default withStyles(styles)(SearchInput);
