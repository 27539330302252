import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
  table: {
    fontFamily: theme.typography.fontFamily
  },
  flexContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1
  },
  checkboxCell: {
    padding: 0,
    width: 50
  },
  tableBody: {
    fontSize: 12
  },
  sortLabel: {
    color: theme.palette.primary.white,
    '&:hover': {
      color: theme.palette.primary.white
    },
    '&:focus': {
      color: theme.palette.primary.white
    }
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.75)
    }
  },
  tableRow: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.light, 0.5)
    }
  },
  noClick: {
    cursor: 'initial'
  }
});
