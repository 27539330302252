export default [
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Name',
    dataKey: 'name'
  },
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Out Protocols',
    dataKey: 'out_protocol_names'
  }
];
