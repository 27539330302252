import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { unixToDate } from 'utils/date';

export default timestamp => {
  if (isNil(timestamp)) {
    return 'N/A';
  }

  return (
    <React.Fragment>
      {unixToDate(timestamp)}&nbsp;-&nbsp;
      <span>
        ({moment.duration(timestamp - moment().unix(), 'seconds').humanize(true)})
      </span>
    </React.Fragment>
  );
};
