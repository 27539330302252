import { cancel, delay, fork, put, take, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { fetch, handleError, poolApi } from 'utils/sagasHelpers';
import { FULFILLED, REJECTED } from './actionTypes';

const FETCH = 'FETCH';
const START_POOLING = 'START_POOLING';
const STOP_POOLING = 'STOP_POOLING';
const ACCESS_LOGS = '_ACCESS_LOGS';
const DATA_LOGS = '_DATA_LOGS';
const REALTIME_DATA = '_REALTIME_DATA';
const RELAY_STATUS = '_RELAY_STATUS';
const TOGGLE_RELAY = 'TOGGLE_RELAY';

// actions
const actions = ({
  fetchRelayStatus: id => ({
    payload: id,
    type: FETCH + RELAY_STATUS
  }),
  startPoolingAccessLogs: id => ({
    payload: id,
    type: START_POOLING + ACCESS_LOGS
  }),
  stopPoolingAccessLogs: id => ({
    payload: id,
    type: STOP_POOLING + ACCESS_LOGS
  }),
  startPoolingDataLogs: id => ({
    payload: id,
    type: START_POOLING + DATA_LOGS
  }),
  stopPoolingDataLogs: id => ({
    payload: id,
    type: STOP_POOLING + DATA_LOGS
  }),
  startPoolingRealtimeData: id => ({
    payload: id,
    type: START_POOLING + REALTIME_DATA
  }),
  stopPoolingRealtimeData: id => ({
    payload: id,
    type: STOP_POOLING + REALTIME_DATA
  }),
  toggleRelay: (id, state) => ({
    payload: { id, state },
    type: TOGGLE_RELAY
  })
});

export { actions };

// reducer
const INITIAL_STATE = {
  access: [],
  data: [],
  realtime: [],
  relays: {},
  error: null,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  const { error, payload, type } = action;

  switch (type) {

    case FETCH + ACCESS_LOGS:
    case FETCH + DATA_LOGS:
    case FETCH + REALTIME_DATA:
    case FETCH + RELAY_STATUS:
      return { ...state, ...INITIAL_STATE, loading: true };

    case FETCH + ACCESS_LOGS + FULFILLED:
      const accessWithId = payload.map(access => ({ ...access, id: access.created_at }));
      return { ...state, loading: false, access: accessWithId };

    case FETCH + DATA_LOGS + FULFILLED:
      return { ...state, loading: false, data: payload };

    case FETCH + REALTIME_DATA + FULFILLED:
      return { ...state, loading: false, realtime: payload };

    case FETCH + RELAY_STATUS + FULFILLED:
      return { ...state, loading: false, relays: payload };

    case FETCH + ACCESS_LOGS + REJECTED:
    case FETCH + DATA_LOGS + REJECTED:
    case FETCH + REALTIME_DATA + REJECTED:
    case FETCH + RELAY_STATUS + REJECTED:
      return { ...state, ...INITIAL_STATE, error: error, loading: false };

    case TOGGLE_RELAY:
      return { ...state, loading: true };

    case TOGGLE_RELAY + REJECTED:
      return { ...state, error: error, loading: false };

    case TOGGLE_RELAY + FULFILLED:
      return { ...state, loading: false, relays: { ...state.relays, [payload.id]: payload.state } };

    default:
      return state;
  }
};

// sagas
const POOL_INTERVAL_ACCESS = 60 * 1000;
const POOL_INTERVAL_DATA = 60 * 1000;
const POOL_INTERVAL_REALTIME = 30 * 1000;

function* poolAccessLog(action) {
  try {
    while (true) {
      yield put({ type: FETCH + ACCESS_LOGS, payload: action.payload });
      yield delay(POOL_INTERVAL_ACCESS);
    }
  } catch (error) {
    console.error(error);
  }
}

function* poolingAccessLogs() {
  try {
    let action = yield take(START_POOLING + ACCESS_LOGS);
    while (action) {
      const task = yield fork(poolAccessLog, action);
      yield take(STOP_POOLING + ACCESS_LOGS);
      yield cancel(task);
      action = yield take(START_POOLING + ACCESS_LOGS);
    }
  } catch (error) {
    console.error(error);
  }
}

function* poolDataLog(action) {
  try {
    while (true) {
      yield put({ type: FETCH + DATA_LOGS, payload: action.payload });
      yield take([
        FETCH + DATA_LOGS + FULFILLED,
        FETCH + DATA_LOGS + REJECTED
      ]);
      yield delay(POOL_INTERVAL_DATA);
    }
  } catch (error) {
    console.error(error);
  }
}

function* poolingDataLogs() {
  try {
    let action = yield take(START_POOLING + DATA_LOGS);
    while (action) {
      const task = yield fork(poolDataLog, action);
      yield take(STOP_POOLING + DATA_LOGS);
      yield cancel(task);
      action = yield take(START_POOLING + DATA_LOGS);
    }
  } catch (error) {
    console.error(error);
  }
}

function* poolRealtime(action) {
  try {
    while (true) {
      yield put({ type: FETCH + REALTIME_DATA, payload: action.payload });
      yield take([FETCH + REALTIME_DATA + FULFILLED, FETCH + REALTIME_DATA + REJECTED]);
      yield delay(POOL_INTERVAL_REALTIME);
    }
  } catch (error) {
    console.error(error);
  }
}

function* poolingRealtime() {
  try {
    let action = yield take(START_POOLING + REALTIME_DATA);
    while (action) {
      const task = yield fork(poolRealtime, action);
      yield take(STOP_POOLING + REALTIME_DATA);
      yield cancel(task);
      action = yield take(START_POOLING + REALTIME_DATA);
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchAccessLogs() {
  yield takeLatest(FETCH + ACCESS_LOGS, poolApi(FETCH + ACCESS_LOGS, '/devices/:id/seed_log'));
}

function* watchDataLogs() {
  yield takeLatest(FETCH + DATA_LOGS, poolApi(FETCH + DATA_LOGS, '/devices/:id/measurements_data'));
}

function* watchFetchRelayStatus() {
  yield takeLatest(FETCH + RELAY_STATUS, fetch(FETCH + RELAY_STATUS, '/devices/:id/relay_status'));
}

function* watchRealtime() {
  yield takeLatest(FETCH + REALTIME_DATA, poolApi(FETCH + REALTIME_DATA, '/devices/:id/realtime'));
}

function* watchToggleRelay() {
  yield takeLatest(TOGGLE_RELAY, function* ({ payload }) {
    try {
      const { id, state } = payload;
      yield apiRequest(`/measurements/${id}/toggle_relay`, 'POST', { state });
      yield put({ type: TOGGLE_RELAY + FULFILLED, payload: { id, state } });
    } catch (error) {
      const errorObject = handleError(TOGGLE_RELAY, error);
      yield put(errorObject);
    }
  });
}

const sagas = [
  fork(poolingAccessLogs),
  fork(watchAccessLogs),
  fork(poolingDataLogs),
  fork(watchDataLogs),
  fork(poolingRealtime),
  fork(watchFetchRelayStatus),
  fork(watchRealtime),
  fork(watchToggleRelay)
];

export { sagas };
