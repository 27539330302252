import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { equipmentsByInProtocolId } from 'selectors/equipments';
import formSelectors from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
  const { buildingId, equipments, firmwares, formValues, metadatas, parentDeviceId, readings, submitting } = props;
  const { create, onSave } = props;

  const equipmentId = formValues.equipment_id;

  const formSchema = [
    {
      formData: equipments.filter(equipment => equipment.usable),
      label: 'Equipment\x2a',
      name: 'equipment_id',
      type: 'select',
      validate: [validators.required]
    }
  ];

  if (equipmentId) {
    const equipment = equipments.find(equipment => equipment.id === equipmentId);

    if (!equipment.name.includes('Wise') || equipment.name === 'WiseBox') {
      formSchema.push({
        label: 'Name tag\x2a',
        name: 'tag',
        type: 'text',
        validate: [validators.required]
      });

      formSchema.push({
        label: 'Description',
        name: 'description',
        rows: 8,
        type: 'textArea'
      });

      formSchema.push(...metadataFields(metadatas));
    }
  }

  if (equipmentId) {
    const equipment = equipments.find(equipment => equipment.id === equipmentId) || {};

    if (equipment.in_protocol_id === 2) {
      const currentReadings = Object.values(readings).filter(reading => {
        return reading.equipment_id === equipmentId && reading.out_protocol_id === 6;
      });

      if (currentReadings.length) {
        formSchema.push({
          type: 'selectMultiple',
          label: 'Activated measurements',
          name: 'activated_measurement_ids',
          formData: sortBy(currentReadings, 'name')
        });
      }
    }

    if (equipment.wise_box) {
      formSchema.push(
        {
          type: 'select',
          label: 'Firmware\x2a',
          name: 'firmware_version',
          validate: [validators.required],
          formData: firmwares.map(firmware => ({ id: firmware, name: firmware }))
        }
      );
    }
  }

  const onSubmit = values => {
    let data = {
      ...serializeForm(values, formSchema, {}),
      ...{ building_id: buildingId, parent_id: parentDeviceId }
    };

    create(data, onSave);
  };

  return (
    <FormViewer label='New device'>
      <Form
        formSchema={formSchema}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  equipments: equipmentsByInProtocolId(state, ownProps),
  firmwares: state.bootstrap.firmwares,
  formValues: formSelectors.getFormValues(state),
  metadatas: selectorMetadatas.metadataFromDevice(state, ownProps),
  readings: state.readings.models,
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  create: deviceActions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
