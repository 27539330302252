import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import LastSync from './LastSync';

const columns = [
  { label: 'Action', dataKey: 'action' },
  { label: 'IP', dataKey: 'ip' },
  { label: 'Method', dataKey: 'method' },
  { label: 'Params', dataKey: 'timestamp' },
  { label: 'Status', dataKey: 'status' },
  { label: 'Headers', dataKey: 'headers', transform: model => JSON.stringify(model.headers) },
  { label: 'Created', dataKey: 'created_at', transform: model => unixToDate(model.created_at) }
];

const Component = props => {
  const { device, loading } = props;
  const { startPoolingAccessLogs, stopPoolingAccessLogs } = props;

  useEffect(() => {
    startPoolingAccessLogs(device.id);

    return () => stopPoolingAccessLogs(device.id);
  }, []);

  const accessLogs = props.accessLogs.map(log => ({ ...log, id: `${log.method}-${log.created_at}-${log.action}` }));

  return (
    <Panel>
      <LastSync loading={loading}/>
      <Table columns={columns} loading={loading} rows={accessLogs}/>
    </Panel>
  );
};

const mapStateToProps = state => ({
  accessLogs: state.logs.access,
  loading: state.logs.loading
});

const mapDispatchToProps = ({
  startPoolingAccessLogs: logActions.startPoolingAccessLogs,
  stopPoolingAccessLogs: logActions.stopPoolingAccessLogs
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
