import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { sortBy } from 'lodash';
import ShowIndicator from 'Pages/Indicators/Show';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import findWhere from 'selectors/findWhere';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const
  columns = [
    { label: 'ID', dataKey: 'id' },
    { label: 'Name', dataKey: 'name' },
    {
      label: 'Last Update',
      dataKey: 'updated_at',
      transform: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}`
    }
  ];

const Component = props => {
  const { buildingId, indicatorIds, indicatorModels } = props;

  const [currentIndicator, setCurrentIndicator] = useState(null);
  const onClose = () => setCurrentIndicator(null);

  let drawer;
  if (currentIndicator) {
    drawer = <ShowIndicator id={currentIndicator} closeDrawer={onClose}/>;
  }

  const indicators = sortBy(
    findWhere(
      indicatorIds.map(id => indicatorModels[id]),
      { building_id: buildingId }
    ),
    'name'
  );

  return (
    <Panel>
      <Drawer big={!!currentIndicator} onClose={onClose} open={!!currentIndicator}>
        {drawer}
      </Drawer>
      <Table columns={columns} onRowClick={indicatorId => setCurrentIndicator(indicatorId)} rows={indicators}/>
    </Panel>
  );
};

const mapStateToProps = state => ({
  indicatorIds: state.indicators.ids,
  indicatorModels: state.indicators.models
});

export default connect(mapStateToProps)(Component);
