import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as indicatorsActions } from 'ducks/indicators';
import { loadBuilding } from 'ducks/loadBuilding';
import moment from 'moment/moment';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const formSchema = [
  {
    type: 'text',
    label: 'Name\x2a',
    name: 'name',
    validate: [validators.required]
  },
  {
    type: 'textArea',
    label: 'Description',
    name: 'description'
  },
  {
    type: 'text',
    label: 'Max value\x2a',
    name: 'max_value',
    validate: [validators.required]
  },
  {
    type: 'text',
    label: 'Min value\x2a',
    name: 'min_value',
    validate: [validators.required]
  },
  {
    type: 'dateTime',
    label: 'Measurement Start',
    name: 'start_time',
    transformation: value => moment(value, 'YYYY-MM-DDTHH:mm').unix()
  }
];

const Component = props => {
  const { device, indicatorKinds, measurement, submitting } = props;
  const { create, loadBuilding } = props;

  const indicatorKind = indicatorKinds.find(kind => kind.id === measurement.indicator_kind_id);

  const formInitialValues = {
    max_value: indicatorKind.max_value,
    min_value: indicatorKind.min_value,
    name: measurement.name
  };

  const onSubmit = values => {
    const data = {
      ...serializeForm(values, formSchema),
      ...{ measurement_id: measurement.id, type: 'raw' }
    };
    create(data, () => loadBuilding(device.building_id));
  };

  return (
    <FormViewer label={'Indicator Form'}>
      <Form
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = state => ({
  indicatorKinds: state.bootstrap.indicator_kinds,
  submitting: state.indicators.submitting
});

const mapDispatchToProps = ({
  create: indicatorsActions.create,
  loadBuilding
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
