import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandedPanel from 'Components/ExpandedPanel';
import SimpleList from 'Components/SimpleList';
import Table from 'Components/SimpleTable';
import TabsToolbar from 'Components/TabsToolbar';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as equipmentActions } from 'ducks/equipment';
import { isBoolean, isObject } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import readingSelectors from 'selectors/readings';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import styles from './styles';

/**
 * Equipment configuration ****************
 */
const equipmentProperties = [
  { label: 'ID', content: model => model.id },
  { label: 'Name', content: model => model.name },
  { label: 'Usable?', content: model => model.usable ? 'Yes' : 'No' },
  { label: 'Equipment category', content: model => model.equipment_category_name },
  { label: 'Input protocol', content: model => model.in_protocol_name },
  { label: 'Output protocol', content: model => model.out_protocol_name },
  { label: 'Organizations', content: model => model.organizations.join(', ') },
  { label: 'Creation', content: model => `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
  { label: 'Update', content: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const generateEquipmentMetadata = (model, baseAttributes) => {
  const attributes = baseAttributes.map(({ label, content }) => ({ content: content(model), label }));

  return [...attributes, ...displayMetadata(model.metadata_values)];
};

const readingProperties = reading => {
  const properties = [{ label: 'Name', dataKey: 'name' }];

  reading.metadata_values.forEach(metadata_value => {
    properties.push({ label: metadata_value.name, dataKey: metadata_value.metadata_id });
  });

  properties.push({ label: 'Output protocol', dataKey: 'out_protocol_name' });
  properties.push({ label: 'Update', dataKey: 'updated_at', transform: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` });

  return properties;
};

const EquipmentPage = props => {
  const { classes, id, loading, loadingReadings, model, readings } = props;
  const { editEntity, newEntity, remove, viewEntity } = props;

  const onDelete = () => remove(id, () => props.onClose());
  const handleEditEquipment = () => editEntity(id, 'EQUIPMENT');
  const handleNewReading = () => newEntity('READING', id);
  const onViewReading = entityId => viewEntity(entityId, 'READING');

  const
    buttons = [
      { icon: icons.editConfig, onClick: handleEditEquipment, tooltip: 'Edit' },
      {
        icon: icons.trash,
        confirmationHeader: 'Delete Confirmation',
        confirmationText: 'Are you sure you want to delete this entity?',
        submitting: loading,
        onClick: onDelete,
        tooltip: 'Delete'
      }
    ],
    tabs = [{ text: 'Equipment details', id: 'properties' }];

  let Readings;

  if (model.can_have_readings) {
    buttons.push({ icon: icons.addEntity, onClick: handleNewReading, tooltip: 'Add reading' });

    const readingList = readings.map(reading => {
      const object = { ...reading };

      reading.metadata_values.forEach(metadata_value => {
        let value = metadata_value.value;

        if (isObject(value)) {
          value = JSON.stringify(value);
        }

        if (isBoolean(value)) {
          value = value ? 'Yes' : 'No';
        }

        object[metadata_value.metadata_id] = value;
      });

      return object;
    });

    if (readings.length) {
      Readings = (
        <ExpandedPanel id='readings' header='Readings' loading={loadingReadings}>
          <Table columns={readingProperties(readings[0])} onRowClick={onViewReading} rows={readingList} rowsPerPage={100}/>
        </ExpandedPanel>
      );
    }

    tabs.push({ text: 'Readings', id: 'readings' });
  }

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Grid container className={classes.headerContainer}>
        <TabsToolbar buttons={props.disableButtons ? [] : buttons} tabs={tabs}/>
      </Grid>
      <Grid item xs={12} className={classes.bottomContainer}>
        <ExpandedPanel id='properties' header='Details'>
          <SimpleList content={generateEquipmentMetadata(model, equipmentProperties)}/>
        </ExpandedPanel>
        {Readings}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id || state.drawerView.entityId;
  return {
    id,
    loading: state.equipments.loading,
    loadingReadings: state.readings.loading,
    model: state.equipments.models[id],
    readings: readingSelectors.readingsByEquipmentId(state, id)
  };
};

const mapDispatchToProps = ({
  editEntity: drawerViewActions.editEntity,
  newEntity: drawerViewActions.newEntity,
  remove: equipmentActions.remove,
  viewEntity: drawerViewActions.viewEntity
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentPage));
