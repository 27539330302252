import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/users';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const formSchema = [
  {
    type: 'text',
    label: 'Name\x2a',
    name: 'name',
    validate: [validators.required]
  },
  {
    type: 'text',
    label: 'Email\x2a',
    name: 'email',
    validate: [validators.required]
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password'
  },
  {
    type: 'password',
    label: 'Password Confirmation',
    name: 'password_confirmation'
  },
  {
    type: 'checkBox',
    label: 'Enabled?',
    name: 'enabled'
  },
  {
    type: 'checkBox',
    label: 'Daily Report?',
    name: 'report'
  }
];

const Component = props => {
  const { currentUser, submitting } = props;
  const { update } = props;

  const onSubmit = values => {
    const data = serializeForm(values, formSchema, currentUser);
    if (isEmpty(data)) {
      return;
    }
    update(currentUser.id, data, () => {});
  };

  return (
    <FormViewer label={'Manage Profile'}>
      <Form
        formSchema={formSchema}
        formInitialValues={currentUser}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = state => ({
  currentUser: state.users.models[state.auth.currentUserId],
  submitting: state.users.submitting
});

const mapDispatchToProps = ({
  update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
