import EntityView from 'Components/EntityView';
import { actions } from 'ducks/protocols';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
  { label: 'ID', content: model.id },
  { label: 'Name', content: model.name },
  { label: 'Output protocols', content: model.out_protocol_names.join(', ') },
  { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
  { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props =>
  <EntityView
    disableDelete
    disableEdit
    label={'Protocol'}
    properties={properties}
    tabsHeader={'Protocol details'}
    {...props}
  />;

const mapStateToProps = state => ({
  loading: state.protocols.loading,
  model: state.protocols.models[state.drawerView.entityId],
  root: state.users.models[state.auth.currentUserId].root
});

const mapDispatchToProps = ({
  remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
