import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';
import Details from './Details';
import Devices from './Devices';
import Indicators from './Indicators';
import styles from './styles';

const Component = props => {
  const { buildingId, classes } = props;
  let section = props.section || 'details', Section;

  switch (section) {
    case 'indicators':
      Section = <Indicators buildingId={buildingId}/>;
      break;
    case 'devices':
      Section = <Devices buildingId={buildingId}/>;
      break;
    default:
      Section = <Details buildingId={buildingId}/>;
  }

  const tabs = [
    { id: 'details', text: 'Details', url: `/buildings/${buildingId}/details` },
    { id: 'devices', text: 'Devices', url: `/buildings/${buildingId}/devices` },
    { id: 'indicators', text: 'Indicators', url: `/buildings/${buildingId}/indicators` }
  ];

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Grid item xs={12} className={classes.headerContainer}>
        <TabsToolbar tabs={tabs} currentTab={section}/>
      </Grid>
      <Grid item xs={12} className={classes.bottomContainer}>
        {Section}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Component);
