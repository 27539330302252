import IconButton from 'Components/Buttons/IconButton';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import PanelDivider from 'Components/PanelDivider';
import Table from 'Components/SimpleTable';
import { actions as logActions } from 'ducks/logs';
import { actions as measurementActions } from 'ducks/measurements';
import ShowReading from 'Pages/Readings/Show';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import findWhere from 'selectors/findWhere';
import sortMeasurements from 'utils/sortMeasurements';
import icons from 'utils/ui/icons';
import EditMeasurement from './Edit';
import NewMeasurement from './New';
import ShowMeasurement from './Show';

const Component = props => {
  const { device, measurements, readings, relays, rootDevice, submitting } = props;
  const { fetchRelayStatus, remove, toggleRelay } = props;

  const isNewWisebox = rootDevice.new_wise_box;

  useEffect(() => {
    if (isNewWisebox) {
      fetchRelayStatus(device.id);
    }
  }, []);

  const [currentDrawer, setDrawer] = useState(false);
  const [entityId, setEntityId] = useState(null);

  const onClose = () => setDrawer(false);
  const onClick = (entityId, drawer) => event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setEntityId(entityId);
    setDrawer(drawer);
  };

  const
    availableColumns = [
      { label: 'Reading ID', dataKey: 'id' },
      { label: 'Reading', dataKey: 'readingName' },
      { label: 'Actions', dataKey: 'actions' }
    ],
    usedColumns = [
      { label: 'ID', dataKey: 'id' },
      { label: 'Reading', dataKey: 'readingName' },
      { label: 'Name', dataKey: 'measurementName' },
      { label: 'Circuit Name', dataKey: 'circuitName' },
      { label: 'Actions', dataKey: 'actions' }
    ];

  const availableRows = [], usedRows = [];

  readings.forEach(reading => {
    let row = {};
    const measurement = measurements.find(measurement => measurement.reading_id === reading.id) || {};

    row.measurementName = measurement.name;
    row.circuitName = measurement.circuit_name;
    row.id = measurement.id || reading.id;
    row.name = reading.name;
    row.readingName = (
      <a href='#' onClick={onClick(reading.id, 'showReading')}>
        {reading.name}
      </a>
    );

    if (measurement.id) {
      const on = relays[measurement.id] === 'on';

      let Actuator;
      if (isNewWisebox) {
        Actuator = (
          <IconButton
            disabled={submitting}
            icon={icons[on ? 'off' : 'on']}
            onClick={() => toggleRelay(measurement.id, on ? 'off' : 'on')}
            submitting={submitting}
            tooltip={`Toggle ${on ? 'Off' : 'On'}`}
          />
        );
      }

      row.actions = (
        <React.Fragment>
          <IconButton icon={icons.details} onClick={onClick(measurement.id, 'showMeasurement')} tooltip='Show measurement'/>
          <IconButton icon={icons.edit} onClick={onClick(measurement.id, 'editMeasurement')} tooltip='Edit measurement'/>
          <IconButton
            confirmationText='Are you sure you want to remove this measurement?'
            disabled={!!measurement.circuit_id}
            icon={icons.destroy}
            onClick={() => remove(measurement.id, () => {})}
            submitting={submitting}
            tooltip='Remove measurement'
          />
          {Actuator}
        </React.Fragment>
      );

      usedRows.push(row);
    } else {
      row.actions = (
        <IconButton icon={icons.add} onClick={onClick(reading.id, 'newMeasurement')} tooltip='Add measurement'/>
      );

      availableRows.push(row);
    }

  });

  let drawer;
  switch (currentDrawer) {
    case 'editMeasurement':
      drawer = <EditMeasurement device={device} id={entityId} closeDrawer={onClose}/>;
      break;
    case 'newMeasurement':
      drawer = <NewMeasurement device={device} readingId={entityId} closeDrawer={onClose}/>;
      break;
    case 'showMeasurement':
      drawer = <ShowMeasurement id={entityId} closeDrawer={onClose}/>;
      break;
    case 'showReading':
      drawer = <ShowReading disableBack disableDelete disableEdit id={entityId}/>;
      break;
  }

  let Used;
  if (usedRows.length) {
    Used = (
      <Panel header='Used Measurements'>
        <Table columns={usedColumns} rows={usedRows.sort(sortMeasurements)}/>
      </Panel>
    );
  }

  let Available;
  if (availableRows.length) {
    Available = (
      <Panel header='Available Measurements'>
        <Table columns={availableColumns} rows={availableRows.sort(sortMeasurements)}/>
      </Panel>
    );
  }

  let Divider;
  if (Available && Used) {
    Divider = (
      <PanelDivider/>
    );
  }

  return (
    <React.Fragment>
      <Drawer big={currentDrawer === 'showIndicator'} onClose={onClose} open={!!currentDrawer}>
        {drawer}
      </Drawer>
      {Used}
      {Divider}
      {Available}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const device = state.devices.models[ownProps.deviceId];

  return {
    device,
    measurements: findWhere(state.measurements.ids.map(id => state.measurements.models[id]), { device_id: device.id }),
    readings: Object.values(state.readings.models).filter(reading => reading.equipment_id === device.equipment_id),
    relays: state.logs.relays,
    rootDevice: state.devices.models[device.root_device_id],
    submitting: state.measurements.submitting || state.indicators.submitting || state.logs.loading
  };
};

const mapDispatchToProps = {
  fetchRelayStatus: logActions.fetchRelayStatus,
  remove: measurementActions.remove,
  toggleRelay: logActions.toggleRelay
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);

