import { AllSubstringsIndexStrategy, Search } from 'js-search';
import { sortBy } from 'lodash';

export default () => ({
  index: new Search('id'),

  rebuild: function (rows, searchableKeys) {
    if (searchableKeys === []) {
      return;
    }

    this.index = new Search('id');
    this.index.indexStrategy = new AllSubstringsIndexStrategy();
    searchableKeys.forEach(key => this.index.addIndex(key));
    this.index.addDocuments(rows);
  },

  searchIds: function (state) {
    const { models, searchValue, sortBy, sortDirection } = state;
    let filteredRows;

    if (searchValue === '') {
      filteredRows = Object.values(models);
    } else {
      filteredRows = this.index.search(searchValue);
    }

    return this.sort(filteredRows, sortBy, sortDirection).map(({ id }) => id);
  },

  sort: function (rows, sortColumn, direction) {
    if (sortColumn === '') {
      return rows;
    }

    const sortedRows = sortBy(
      rows,
      row => {
        const value = (row || {})[sortColumn];
        return value && value.toLowerCase ? value.toLowerCase() : value;
      }
    );

    if (direction === 'DESC') {
      return sortedRows.reverse();
    } else {
      return sortedRows;
    }
  }
});
