import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import { closeSidebar } from 'ducks/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from '~/history';

const drawerWidth = 260;

const styles = {
  fullList: {
    width: 'auto'
  },
  list: {
    width: drawerWidth,
    overflowY: 'auto'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerContent: {
    width: drawerWidth,
    position: 'relative'
  },
  drawerModal: {
    top: 64
  }
};

const navigate = event => history.push(event.currentTarget.dataset.url);

const renderSubsection = ({ icon, text, url }) => (
  <ListItem button key={url} data-url={url} onClick={navigate}>
    <ListItemIcon style={{ width: 16 }}>
      <Icon icon={icon} pull='right'/>
    </ListItemIcon>
    <ListItemText primary={text}/>
  </ListItem>
);

const renderSections = sections => (
  sections.map(({ name, subsections }, index) => (
    <React.Fragment key={index}>
      <List dense subheader={<ListSubheader component="div" disableSticky>{name}</ListSubheader>}>
        {subsections.map(renderSubsection)}
      </List>
      {index === sections.length - 1 ? null : <Divider/>}
    </React.Fragment>
  ))
);

const Sidebar = props => {
  const { classes, sections, showSidebar } = props;
  const { closeSidebar } = props;

  return (
    <Drawer
      classes={{ paper: classes.drawerContent, modal: classes.drawerModal }}
      className={classes.drawer}
      onClose={closeSidebar}
      open={showSidebar}
    >
      <div
        onClick={closeSidebar}
        onMouseLeave={closeSidebar}
        onKeyDown={closeSidebar}
        role="button"
        tabIndex={0}
      >
        <div className={classes.list}>
          {renderSections(sections)}
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  showMenu: PropTypes.bool.isRequired,
  showSidebar: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  showMenu: state.ui.showMenu,
  showSidebar: state.ui.showSidebar
});

const mapDispatchToProps = ({ closeSidebar });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar));
