import PropertyView from 'Components/PropertyView';
import Message from 'Components/UserFeedback';
import { actions as drawerViewActions } from 'ducks/drawerView';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Component = props => {
  const { disableBack, disableEdit, disableDelete, entityId, entityType, loading, model, panelHeader, properties, tabsHeader } = props;
  const { editEntity, previousState, remove } = props;

  const attributes = model ? properties(model) : [];

  let onBack = () => previousState();
  if (disableBack) {
    onBack = null;
  }

  let onDelete = (id, redirect) => () => remove(id, redirect);
  if (disableDelete) {
    onDelete = null;
  }

  let onEdit = () => editEntity(entityId, entityType);
  if (disableEdit) {
    onEdit = null;
  }

  return (
    <React.Fragment>
      <PropertyView
        tabsHeader={tabsHeader}
        panelHeader={panelHeader}
        loading={loading}
        id={entityId}
        onBack={onBack}
        onEdit={onEdit}
        onDelete={onDelete && onDelete(entityId, onBack)}
        properties={attributes}
      />
      <Message/>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  entityId: state.drawerView.entityId
});

const mapDispatchToProps = ({
  editEntity: drawerViewActions.editEntity,
  previousState: drawerViewActions.previousState
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  entityType: PropTypes.string,
  panelHeader: PropTypes.string,
  properties: PropTypes.func,
  model: PropTypes.object,
  tabsHeader: PropTypes.string
};
