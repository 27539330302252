import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from 'Components/Drawer';
import TabsToolbar from 'Components/TabsToolbar';
import { actions as indicatorsActions } from 'ducks/indicators';
import { loadBuilding } from 'ducks/loadBuilding';
import EditIndicator from 'Pages/Indicators/Edit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import Info from './Info';
import styles from './styles';

const IndicatorPage = props => {
  const { actions = true, classes, id, loading, model } = props;
  const { fetchOne, loadBuilding, remove } = props;

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    fetchOne(id);
  }, []);

  const onDelete = () => remove(id, () => { loadBuilding(model.building_id); });
  const onEdit = () => setOpenDrawer(true);
  const onClose = () => setOpenDrawer(false);

  let buttons = [];
  if (actions) {
    buttons = [
      { icon: icons.editConfig, onClick: onEdit, tooltip: 'Edit' },
      {
        icon: icons.trash,
        confirmationHeader: 'Delete Confirmation',
        confirmationText: 'Are you sure you want to delete this entity?',
        submitting: loading,
        onClick: onDelete,
        tooltip: 'Delete'
      }
    ];
  }

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Grid container item xs={12} className={classes.headerContainer}>
        <TabsToolbar
          buttons={buttons}
          tabs={[
            { text: 'Indicator details', id: 'properties' },
            { text: 'Measurements', id: 'measurements' }
          ]}
        />
      </Grid>
      <Grid item xs={12} className={classes.bottomContainer}>
        <Info model={model}/>
      </Grid>
      <Drawer onClose={onClose} open={openDrawer}>
        <EditIndicator indicator={model} closeDrawer={onClose}/>
      </Drawer>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id || ownProps.match.params.indicatorId,
  loading: state.indicators.loading,
  model: state.indicators.models[ownProps.id || ownProps.match.params.indicatorId]
});

const mapDispatchToProps = ({
  fetchOne: indicatorsActions.fetchOne,
  loadBuilding: loadBuilding,
  remove: indicatorsActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IndicatorPage));
