import FormDrawer from 'Components/FormDrawer';
import Table from 'Components/Table';
import TableActions from 'Components/Table/Actions';
import { actions as drawerViewActions } from 'ducks/drawerView';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Component = props => {
  const { big, context, columns, editComponent, newComponent, showComponent } = props;
  const { clearEntity, newEntity, viewEntity } = props;

  let onNewEntityClick;
  if (newComponent) {
    onNewEntityClick = () => newEntity();
  }

  let onRowClick = props.onRowClick;
  if (!onRowClick && showComponent) {
    onRowClick = event => viewEntity(event.target.dataset.id);
  }

  const onClose = () => clearEntity();

  return (
    <React.Fragment>
      <FormDrawer
        big={big}
        context={context}
        editComponent={editComponent}
        newComponent={newComponent}
        onClose={onClose}
        showComponent={showComponent}
      />
      <Table
        allChecked={props.allChecked}
        changeSearch={props.changeSearch}
        changeSort={props.changeSort}
        checked={props.checked}
        columns={columns}
        fetchData={props.fetchData}
        ids={props.ids}
        loading={props.loading}
        models={props.models}
        onRowClick={onRowClick}
        searching={props.searching}
        searchValue={props.searchValue}
        sortBy={props.sortBy}
        sortDirection={props.sortDirection}
        toggleAll={props.toggleAll}
        toggleOne={props.toggleOne}
        TableActions={
          <TableActions
            classes={props.classes}
            fetchData={props.fetchData}
            loading={props.loading}
            newEntity={onNewEntityClick}
          />
        }
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ drawerView: { entityId, context } }) => ({ entityId, context });

const { clearEntity, newEntity, viewEntity } = drawerViewActions;

const mapDispatchToProps = ({
  clearEntity,
  newEntity,
  viewEntity
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  columns: PropTypes.array,
  editComponent: PropTypes.func,
  newComponent: PropTypes.func,
  showComponent: PropTypes.func
};
