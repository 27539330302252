import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import history from '~/history';
import { FULFILLED, LOGIN, LOGOUT, REJECTED } from './actionTypes';

// actions
export const login = (email, password) => ({
  type: LOGIN,
  payload: { email, password }
});

export const logout = () => ({
  type: LOGOUT
});

// reducer
const INITIAL_STATE = {
  currentUserId: null,
  organizations: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOGIN + FULFILLED:
      return {
        currentUserId: payload.id,
        organizations: payload.organizations
      };

    default:
      return state;
  }
};

// sagas
function* loginSaga({ payload }) {
  try {
    const response = yield apiRequest('/login', 'POST', { email: payload.email, password: payload.password });
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    const userResponse = yield apiRequest('/users/me');
    if (userResponse.data.config) {
      yield put({ type: LOGIN + FULFILLED, payload: userResponse.data });
      history.push('/');
      yield put({ type: 'BOOT' });
    } else {
      delete axios.defaults.headers.common['Authorization'];
      yield put({ type: LOGIN + REJECTED, payload: 'user does not have permission to access config' });
    }

  } catch (error) {
    console.error(error);
    yield put({ type: LOGIN + REJECTED, payload: error });
  }
}

function* logoutSaga() {
  try {
    yield apiRequest('/logout', 'POST');
    delete axios.defaults.headers.common['Authorization'];
  } catch (error) {
    console.error(error);
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN, loginSaga);
}

function* watchLogout() {
  yield takeLatest(LOGOUT, logoutSaga);
}

const sagas = [
  fork(watchLogin),
  fork(watchLogout)
];

export { sagas };
