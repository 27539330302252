import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'Components/Icon';
import React from 'react';
import icons from 'utils/ui/icons';

const styles = () => ({
  button: {
    fontSize: 20
  }
});

const TableActions = ({ children, classes, loading, fetchData, newEntity }) => {
  let newEntityButton;
  if (newEntity) {
    newEntityButton = (
      <Tooltip title='New entity'>
        <IconButton className={classes.button} onClick={newEntity}>
          <Icon icon={icons.plus} size='xs' fixedWidth/>
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <React.Fragment>
        {newEntityButton}
        <Tooltip title='Refresh data'>
          <IconButton className={classes.button} disabled={loading} onClick={() => fetchData()}>
            <Icon icon={icons.sync_alt} size='xs' spin={loading} fixedWidth/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default withStyles(styles)(TableActions);
