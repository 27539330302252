import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import withStyles from '@material-ui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles';

const scrollTo = id => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const Component = props => {
  const { classes, currentTab, elevation, tabs } = props;

  let selectedTab, onChange;
  if (currentTab) {
    selectedTab = tabs.map(tab => tab.id).indexOf(currentTab);
    if (selectedTab === -1) {
      selectedTab = 0;
    }
  } else {
    const [stateSelectedTab, setTab] = useState(0);

    selectedTab = stateSelectedTab;
    onChange = (event, index) => {
      setTab(index);
      scrollTo(tabs[index].id);
    };
  }

  let TabsList = tabs.map(tab => {
    const UserTab = (
      <Tab key={tab.id} label={tab.text} className={classes.tab}/>
    );

    if (tab.url) {
      return (
        <Tab key={tab.id} label={tab.text} className={classes.tab} component={Link} to={tab.url}/>
      );
    } else {
      return UserTab;
    }
  });

  return (
    <Paper square elevation={elevation}>
      <Tabs
        className={classes.tabs}
        indicatorColor='primary'
        onChange={onChange}
        textColor='primary'
        value={selectedTab}
      >
        {TabsList}
      </Tabs>
    </Paper>
  );
};

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  elevation: PropTypes.number
};
