import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import sortMeasurements from 'utils/sortMeasurements';

const Component = props => {
  const { device, devices, measurements, metadatas, reading, submitting } = props;
  const { create, closeDrawer } = props;

  const formSchema = [];

  if (['WiseInput', 'WiseCurrent'].includes(device.equipment_name)) {
    formSchema.push(
      {
        label: 'Name\x2a',
        name: 'name',
        type: 'text',
        validate: [validators.required]
      }
    );
  }

  if (device.equipment_category_name === 'Digital Meter') {
    const usedPortIds = [];
    Object.values(measurements).forEach(measurement => {
      if (measurement.port_id && devices[measurement.device_id].parent_id === device.parent_id) {
        usedPortIds.push(measurement.port_id);
      }
    });

    const availablePorts = Object.values(measurements).filter(measurement => measurement.device_id === device.parent_id && !usedPortIds.includes(measurement.id)).sort(sortMeasurements);

    formSchema.push({
      formData: availablePorts,
      label: 'Connected Port\x2a',
      name: 'port_id',
      type: 'select',
      validate: [validators.required]
    });
  }

  formSchema.push(...metadataFields(metadatas));

  const onSubmit = formValues => {
    let data = {
      ...serializeForm(formValues, formSchema),
      device_id: device.id,
      reading_id: reading.id
    };

    create(data, closeDrawer);
  };

  return (
    <FormViewer label='Add Measurement'>
      <Form
        formInitialValues={{ metadata_values: [], name: reading.name }}
        formSchema={formSchema}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  devices: state.devices.models,
  measurements: state.measurements.models,
  metadatas: selectorMetadatas.metadataFromMeasurement(state, ownProps),
  reading: state.readings.models[ownProps.readingId],
  submitting: state.measurements.submitting
});

const mapDispatchToProps = ({
  create: measurementActions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
