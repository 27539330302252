import axios from 'axios';
import { apiRequest } from 'utils/request';

const setToken = token => axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const fetchUser = async () => {
  const userResponse = await apiRequest('/users/me');
  return Promise.resolve(userResponse.data);
};

export default async () => {
  try {
    const tokenResponse = await apiRequest('/token');
    setToken(tokenResponse.data.token);

    return fetchUser();
  } catch (error) {
    return Promise.reject(error);
  }
};
