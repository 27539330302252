import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import { actions as buildingActions } from 'ducks/buildings';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';

const buildingProperties = model => [
  { label: 'ID', content: model.id },
  { label: 'Organization', content: model.organization_name },
  { label: 'Name', content: model.name },
  { label: 'Address', content: model.address.full_address },
  { label: 'Coordinates', content: `lat: ${model.address.latitude} - lng: ${model.address.longitude}` },
  { label: 'Creation', content: `${unixToDate(model.created_at)} - ${getUserName(model.created_by)}` },
  { label: 'Update', content: `${unixToDate(model.updated_at)} - ${getUserName(model.updated_by)}` }
];

const Component = props => {
  const
    { building, submitting } = props,
    { remove } = props;

  let buttons = [
    {
      confirmationHeader: 'Delete Confirmation',
      confirmationText: 'Are you sure you want to delete?',
      submitting: submitting,
      icon: icons.trash,
      onClick: () => remove(building.id, '/map'),
      tooltip: 'Delete',
      disabled: true
    }
  ];

  return (
    <Panel buttons={buttons}>
      <SimpleList content={buildingProperties(building)}/>
    </Panel>
  );
};

const mapStateToProps = (state, ownProps) => ({
  building: state.buildings.models[ownProps.buildingId],
  submitting: state.buildings.submitting
});

const mapDispatchToProps = ({
  remove: buildingActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
