import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import styles from './styles';

const Component = ({ classes }) => (
  <div className={classes.container}>
    <LinearProgress/>
  </div>
);

export default withStyles(styles)(Component);
