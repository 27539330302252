export default {
  activate: ['fas', 'thumbs-up'],
  actuators: ['fas', 'toggle-on'],
  add: ['fas', 'plus-square'],
  addEntity: ['fas', 'layer-plus'],
  addSimple: ['fas', 'plus'],
  administration: ['fas', 'user-lock'],
  aggregator: ['fas', 'chart-network'],
  alarms: ['fas', 'alarm-clock'],
  attributes: ['fas', 'list'],
  back: ['fas', 'arrow-left'],
  bug: ['fas', 'bug'],
  buildingDetails: ['fas', 'building'],
  buildings: ['fas', 'building'],
  center: ['fas', 'expand'],
  check: ['fas', 'check-circle'],
  checkSquare: ['fas', 'check-square'],
  circle_notch: ['fas', 'circle-notch'],
  circuits: ['fas', 'network-wired'],
  close: ['fas', 'times'],
  closeMenu: ['fas', 'caret-up'],
  closeNode: ['fas', 'angle-up'],
  cloudDownload: ['fas', 'cloud-download'],
  cloudUpload: ['fas', 'cloud-upload'],
  collapse: ['fas', 'compress-alt'],
  config: ['fas', 'browser'],
  configure: ['fas', 'cogs'],
  connect: ['fas', 'link'],
  csv: ['fas', 'file-csv'],
  dataLog: ['fas', 'chart-area'],
  delays: ['fas', 'clipboard-list-check'],
  delete: ['fas', 'minus-square'],
  destroy: ['fas', 'times-square'],
  details: ['fas', 'info-square'],
  devices: ['fas', 'charging-station'],
  deviceTree: ['fas', 'folder-tree'],
  disconnect: ['fas', 'unlink'],
  download: ['fas', 'download'],
  edit: ['fas', 'pen-square'],
  editConfig: ['fas', 'pencil-alt'],
  entities: ['fas', 'address-card'],
  equipment: ['fas', 'warehouse-alt'],
  equipmentCategories: ['fas', 'clipboard-list'],
  error: ['fas', 'exclamation-circle'],
  events: ['fas', 'calendar-exclamation'],
  expand: ['fas', 'chevron-down'],
  externalLink: ['fas', 'external-link-alt'],
  features: ['fas', 'user-chart'],
  fileDownload: ['fas', 'file-download'],
  fileUpload: ['fas', 'file-upload'],
  filter: ['fas', 'filter'],
  folders: ['fas', 'folder'],
  global: ['fas', 'globe'],
  groups: ['fas', 'users-class'],
  hardware: ['fas', 'microchip'],
  hotel: ['fas', 'hotel'],
  indicators: ['fas', 'analytics'],
  industry: ['fas', 'industry-alt'],
  info: ['fas', 'info-circle'],
  kitchen: ['fas', 'hat-chef'],
  lighting: ['fas', 'lightbulb'],
  link: ['fas', 'layer-plus'],
  logout: ['fas', 'door-open'],
  logs: ['fas', 'scanner'],
  low_vision: ['fas', 'low-vision'],
  mail: ['fas', 'envelope'],
  manage: ['fas', 'tools'],
  map: ['fas', 'map-marked-alt'],
  mapMarker: ['fas', 'map-marker'],
  mapStatus: ['fas', 'map-marked-alt'],
  markers: ['fas', 'person-sign'],
  messages: ['fas', 'envelope'],
  metadata: ['fas', 'tags'],
  metadataRule: ['fas', 'gavel'],
  meterElectric: ['fas', 'bolt'],
  moving_equipment: ['fas', 'ramp-loading'],
  notifications: ['fas', 'bell'],
  on: ['fas', 'toggle-off'],
  off: ['fas', 'toggle-on'],
  office: ['fas', 'building'],
  openMenu: ['fas', 'caret-down'],
  openNode: ['fas', 'angle-down'],
  outlets: ['fas', 'plug'],
  parking: ['fas', 'parking'],
  password: ['fas', 'fingerprint'],
  plus: ['fas', 'plus'],
  print: ['fas', 'print'],
  profile: ['fas', 'id-card-alt'],
  protocol: ['fas', 'project-diagram'],
  readConf: ['fas', 'chart-network'],
  readings: ['fas', 'monitor-heart-rate'],
  refresh: ['fas', 'sync-alt'],
  refrigeration: ['fas', 'temperature-frigid'],
  regenerate: ['fas', 'retweet'],
  regimes: ['fas', 'calendar-week'],
  replace: ['fas', 'exchange'],
  reports: ['fas', 'file-contract'],
  restart: ['fas', 'retweet'],
  restaurant: ['fas', 'utensils-alt'],
  retail: ['fas', 'store-alt'],
  root: ['fas', 'user-secret'],
  rules: ['fas', 'lightbulb-on'],
  rulesConf: ['fas', 'ruler'],
  schedules: ['fas', 'calendar-alt'],
  school: ['fas', 'school'],
  search: ['fas', 'search'],
  select_all: ['fas', 'check-square'],
  select_none: ['fas', 'square'],
  sets: ['fas', 'chart-network'],
  shopping: ['fas', 'bags-shopping'],
  signal: ['fas', 'signal'],
  signalSlash: ['fas', 'signal-slash'],
  sliders_v: ['fas', 'sliders-v'],
  sort: ['fas', 'sort-alt'],
  spinner: ['fas', 'spinner'],
  square: ['fas', 'square'],
  stadium: ['fas', 'whistle'],
  start: ['fas', 'play'],
  stop: ['fas', 'stop'],
  storage: ['fas', 'box-full'],
  store: ['fas', 'store'],
  sync_alt: ['fas', 'sync-alt'],
  table: ['fas', 'table'],
  tableStatus: ['fas', 'table'],
  tags: ['fas', 'tags'],
  tariffs: ['fas', 'money-check-alt'],
  template: ['fas', 'folder-tree'],
  templateDownload: ['fas', 'file-export'],
  test: ['fas', 'tachometer-alt-fastest'],
  tooltip: ['fas', 'question-circle'],
  trash: ['fas', 'trash'],
  tree: ['fas', 'folder-tree'],
  unclaimed: ['fas', 'inbox'],
  unlink: ['fas', 'layer-minus'],
  upload: ['fas', 'upload'],
  user: ['fas', 'user-circle'],
  users: ['fas', 'users'],
  warehouse: ['fas', 'warehouse-alt'],
  warning: ['fas', 'exclamation-triangle'],
  wikipedia: ['fab', 'wikipedia-w'],
  zones: ['fas', 'layer-group']
};
