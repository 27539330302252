import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as measurementActions } from 'ducks/measurements';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
  const { device, firmwares, metadatas, submitting } = props;
  const { closeDrawer, fetchMeasurements, update } = props;

  let formSchema = [
    {
      type: 'text',
      label: 'Name tag\x2a',
      name: 'tag',
      validate: [validators.required]
    },
    {
      type: 'textArea',
      label: 'Description',
      name: 'description',
      rows: 8
    }
  ];

  if (device.wise_box) {
    formSchema.push(
      {
        type: 'select',
        label: 'Firmware\x2a',
        name: 'firmware_version',
        validate: [validators.required],
        formData: firmwares.map(firmware => ({ id: firmware, name: firmware }))
      }
    );
  }

  formSchema.push(...metadataFields(metadatas, device));

  useEffect(() => {
    props.fetchDevice(device.id);
  }, []);

  const onSubmit = values => update(
    device.id,
    serializeForm(values, formSchema, device),
    () => {
      fetchMeasurements({ device_id: device.id });
      closeDrawer();
    }
  );

  return (
    <FormViewer label='Edit Device'>
      <Form
        formSchema={formSchema}
        formInitialValues={device}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  device: state.devices.models[ownProps.id],
  firmwares: state.bootstrap.firmwares,
  metadatas: selectorMetadatas.metadataFromDevice(state, ownProps),
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  fetchDevice: deviceActions.fetchOne,
  fetchMeasurements: measurementActions.fetchAll,
  update: deviceActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
