export default status => {
  status = status && status.toLowerCase();
  switch (status) {
    case 'delayed':
    case 'down':
      return '#B94A48';

    case 'disabled':
    case 'stopped':
      return '#42A5F5';

    case 'cache':
    case 'ok':
      return '#2C6700';

    case 'warning':
      return '#EBEB1A';

    case 'ahead':
    case 'problem':
      return '#E59400';

    default:
      return '#888888';
  }
};
