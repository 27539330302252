import Button from 'Components/Buttons/Button';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import usePrevious from 'utils/usePrevious';

const MAX_FTT = 100;

const Component = props => {
  const { device, submitting } = props;
  const { onSave, uploadFtt } = props;
  const [files, setFile] = useState([]);
  const previousSubmitting = usePrevious(submitting);

  useEffect(() => {
    if (!submitting && previousSubmitting) {
      onSave();
    }
  }, [submitting]);

  const onChange = event => {
    if (event.target.files.length > MAX_FTT) {
      event.target.value = '';
    } else {
      const fileList = [];
      for (let i = 0; i < event.target.files.length; i++) {
        fileList.push(event.target.files[i]);
      }
      setFile(fileList);
    }
  };

  const onClick = () => uploadFtt(device.id, files);

  return (
    <FormViewer label='Upload FTT files'>
      <div style={{ marginLeft: 10, fontSize: 12 }}>* You may choose a maximum of {MAX_FTT} FTTs at a time</div>
      <input
        accept='.FTT'
        id='raised-button-file'
        onChange={onChange}
        multiple
        style={{ margin: 10 }}
        type='file'
      />
      <label htmlFor='raised-button-file'>
        <Button disabled={submitting || !files.length} label='Upload' onClick={onClick} submitting={submitting}/>
      </label>
    </FormViewer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  device: state.devices.models[ownProps.deviceId],
  submitting: state.devices.submitting
});

const mapDispatchToProps = ({
  uploadFtt: deviceActions.uploadFtt
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
