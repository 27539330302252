import IconButton from 'Components/Buttons/IconButton';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import PanelDivider from 'Components/PanelDivider';
import Table from 'Components/SimpleTable';
import { actions as measurementActions } from 'ducks/measurements';
import ShowReading from 'Pages/Readings/Show';
import MeasurementData from 'Pages/Tree/Device/MeasurementData';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import findWhere from 'selectors/findWhere';
import sortMeasurements from 'utils/sortMeasurements';
import statusToColor from 'utils/statusToColor';
import styleTime from 'utils/styleTime';
import icons from 'utils/ui/icons';
import EditMeasurement from './Edit';
import NewMeasurement from './New';
import RestartMeasurement from './Restart';
import ShowMeasurement from './Show';

const Component = props => {
  const { device, measurements, readings, submitting } = props;
  const { create, remove, stopMeasurement, update } = props;

  const [currentDrawer, setDrawer] = useState(false);
  const [measurement, setMeasurement] = useState(null);

  const onClose = () => setDrawer(false);
  const onClick = (measurement, drawer) => event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setMeasurement(measurement);
    setDrawer(drawer);
  };

  const
    isDigitalMeter = device.equipment_category_name === 'Digital Meter',
    availableColumns = [
      { label: 'Reading ID', dataKey: 'id' },
      { label: 'Reading', dataKey: 'readingName' },
      { label: 'Actions', dataKey: 'actions' }
    ],
    usedColumns = [
      { label: 'Data', dataKey: 'data' },
      { label: 'Status', dataKey: 'status' },
      { label: 'Name', dataKey: 'measurementName' },
      { label: 'ID', dataKey: 'id' },
      { label: 'Last Acquisition', dataKey: 'last_acquisition' },
      { label: 'Actions', dataKey: 'actions' }
    ];

  if (isDigitalMeter) {
    usedColumns.splice(2, 0, { label: 'Connected Port', dataKey: 'port_name' });
  }

  const availableRows = [], usedRows = [];

  readings.forEach(reading => {
    const
      row = {},
      measurement = measurements.find(measurement => measurement.reading_id === reading.id) || {};

    row.id = measurement.id || reading.id;
    row.name = reading.name;

    if (measurement.id) {
      let Edit;
      if (['Digital Meter', 'Modbus Bridge', 'WiseCurrent', 'WiseInput'].includes(device.equipment_category_name) || measurement.a_formula) {
        Edit = (
          <IconButton icon={icons.edit} onClick={onClick(measurement, 'editMeasurement')} tooltip='Edit Measurement'/>
        );
      }

      let Restart;
      if (measurement.stopped_at) {
        if (isDigitalMeter) {
          Restart = (
            <IconButton icon={icons.restart} onClick={onClick(measurement, 'restartMeasurement')} tooltip='Restart Measurement'/>
          );
        } else {
          Restart = (
            <IconButton
              confirmationText='Are you sure you want to restart this measurement?'
              disabled={!measurement.stopped_at}
              icon={icons.restart}
              onClick={() => update(measurement.id, { stopped_at: null }, () => {})}
              submitting={submitting}
              tooltip='Restart measurement'
            />
          );
        }
      }

      row.actions = (
        <React.Fragment>
          <IconButton icon={icons.details} onClick={onClick(measurement, 'showMeasurement')} tooltip='Show Measurement'/>
          {Edit}
          {Restart}
          {!measurement.stopped_at && <IconButton
            confirmationText='Are you sure you want to stop this measurement?'
            disabled={!!measurement.stopped_at}
            icon={icons.stop}
            onClick={() => stopMeasurement(measurement.id, () => {})}
            submitting={submitting}
            tooltip='Stop measurement'
          />}
          {!measurement.indicator_id && <IconButton
            confirmationText='Are you sure you want to remove this measurement? This will also remove all the bucket data.'
            disabled={!!measurement.indicator_id}
            icon={icons.destroy}
            onClick={() => remove(measurement.id, () => {})}
            submitting={submitting}
            tooltip='Remove measurement'
          />}
        </React.Fragment>
      );

      row.port_name = measurement.port_name || '-';

      row.data = (
        <IconButton icon={icons.table} onClick={onClick(measurement, 'showData')} tooltip='Measurement Data'/>
      );

      row.last_acquisition = styleTime(measurement.last_timestamp);

      row.measurementName = (
        <a href='#' onClick={onClick({ reading_id: reading.id }, 'showReading')}>{measurement.name}</a>
      );

      let status = measurement.status;
      if (measurement.stopped_at) {
        status = (
          <React.Fragment>
            {measurement.status} @ {styleTime(measurement.stopped_at)}
          </React.Fragment>
        );
      }

      row.status = (
        <React.Fragment>
            <span style={{ color: statusToColor(measurement.status) }}>
              {status}
            </span>
        </React.Fragment>
      );

      usedRows.push(row);
    } else {
      row.readingName = (
        <a href='#' onClick={onClick({ reading_id: reading.id }, 'showReading')}>{reading.name}</a>
      );

      if (['Digital Meter', 'Modbus Bridge', 'WiseCurrent', 'WiseInput'].includes(device.equipment_category_name)) {
        row.actions = (
          <IconButton icon={icons.start} onClick={onClick({ reading_id: reading.id }, 'newMeasurement')} tooltip='Start Measurement'/>
        );
      } else {
        row.actions = (
          <IconButton
            confirmationText='Are you sure you want to start this measurement?'
            icon={icons.start}
            onClick={() => create({ device_id: device.id, reading_id: reading.id }, () => {})}
            submitting={submitting}
            tooltip='Start measurement'
          />
        );
      }

      availableRows.push(row);
    }
  });

  let drawer;
  switch (currentDrawer) {
    case 'editMeasurement':
      drawer = <EditMeasurement device={device} measurement={measurement} closeDrawer={onClose}/>;
      break;
    case 'showData':
      drawer = <MeasurementData id={measurement.id}/>;
      break;
    case 'newMeasurement':
      drawer = <NewMeasurement device={device} readingId={measurement.reading_id} closeDrawer={onClose}/>;
      break;
    case 'restartMeasurement':
      drawer = <RestartMeasurement closeDrawer={onClose} measurementId={measurement.id}/>;
      break;
    case 'showMeasurement':
      drawer = <ShowMeasurement closeDrawer={onClose} measurementId={measurement.id}/>;
      break;
    case 'showReading':
      drawer = <ShowReading disableBack disableDelete disableEdit id={measurement.reading_id}/>;
      break;
  }

  let Used;
  if (usedRows.length) {
    Used = (
      <Panel header='Used Measurements'>
        <Table columns={usedColumns} rows={usedRows.sort(sortMeasurements)}/>
      </Panel>
    );
  }

  let Available;
  if (availableRows.length) {
    Available = (
      <Panel header='Available Measurements'>
        <Table columns={availableColumns} rows={availableRows.sort(sortMeasurements)}/>
      </Panel>
    );
  }

  let Divider;
  if (Available && Used) {
    Divider = (
      <PanelDivider/>
    );
  }

  return (
    <React.Fragment>
      <Drawer big={currentDrawer === 'showIndicator'} onClose={onClose} open={!!currentDrawer}>
        {drawer}
      </Drawer>
      {Used}
      {Divider}
      {Available}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const device = state.devices.models[ownProps.deviceId];

  return {
    device,
    measurements: findWhere(state.measurements.ids.map(id => state.measurements.models[id]), { device_id: device.id }),
    readings: Object.values(state.readings.models).filter(reading => reading.equipment_id === device.equipment_id),
    submitting: state.measurements.submitting || state.indicators.submitting
  };
};

const mapDispatchToProps = {
  create: measurementActions.create,
  remove: measurementActions.remove,
  stopMeasurement: measurementActions.stopMeasurement,
  update: measurementActions.update
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
