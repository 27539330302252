import { fork, put, takeLatest } from 'redux-saga/effects';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { FETCH_MEASUREMENT, FULFILLED, LOAD_BUILDING, MEASUREMENT, REJECTED } from './actionTypes';

const CONNECT_MEASUREMENT = 'CONNECT_MEASUREMENT';
const DISCONNECT_MEASUREMENT = 'DISCONNECT_MEASUREMENT';
const FETCH_MEASUREMENT_DATA = 'FETCH_MEASUREMENT_DATA';
const STOP_MEASUREMENT = 'STOP_MEASUREMENT';

// actions
const actions = actionsFactory(MEASUREMENT);

actions.connectMeasurement = (id, measurementId, callback) => ({
  payload: { id, measurementId },
  type: CONNECT_MEASUREMENT,
  meta: callback
});

actions.disconnectMeasurement = id => ({
  payload: id,
  type: DISCONNECT_MEASUREMENT
});

actions.fetchData = id => ({
  payload: id,
  type: FETCH_MEASUREMENT_DATA
});

actions.stopMeasurement = id => ({
  payload: id,
  type: STOP_MEASUREMENT
});

actions.stopMeasurement = id => ({
  payload: id,
  type: STOP_MEASUREMENT
});

export { actions };

// reducer
let initialState = initialStateFactory();
initialState.data = []; // this will hold measurement data

const reducer = reducerFactory(MEASUREMENT);

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONNECT_MEASUREMENT:
    case DISCONNECT_MEASUREMENT:
    case STOP_MEASUREMENT:
      return reducer({ ...state, errorMessage: '', submitting: true }, action);

    // This is here as documentation, we're gonna dispatch a LOAD_BUILDING on
    // the saga because this action will update multiple things that need to be re-fetched
    case CONNECT_MEASUREMENT + FULFILLED:
    case DISCONNECT_MEASUREMENT + FULFILLED:
    case STOP_MEASUREMENT + FULFILLED:
      return reducer({ ...state, errorMessage: '', submitting: false }, action);

    case CONNECT_MEASUREMENT + REJECTED:
    case DISCONNECT_MEASUREMENT + REJECTED:
    case STOP_MEASUREMENT + REJECTED:
      return reducer({ ...state, errorMessage: payload, submitting: false }, action);

    case FETCH_MEASUREMENT_DATA:
      return reducer({ ...state, data: [], errorMessage: '', loading: true }, action);

    case FETCH_MEASUREMENT_DATA + FULFILLED:
      return reducer({ ...state, data: action.payload, errorMessage: '', loading: false }, action);

    case FETCH_MEASUREMENT_DATA + REJECTED:
      return reducer({ ...state, data: [], errorMessage: payload, loading: false }, action);

    default:
      return reducer(state, action);
  }
};

// sagas
const sagas = sagasFactory(MEASUREMENT, '/measurements');

function* watchConnect() {
  yield takeLatest(CONNECT_MEASUREMENT, function* ({ payload, meta: callback }) {
    try {
      const response = yield apiRequest(`/measurements/${payload.id}/connect`, 'PUT', { measurement_id: payload.measurementId });
      yield put({ type: CONNECT_MEASUREMENT + FULFILLED });
      if (callback) {
        callback();
      }
      yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
    } catch (error) {
      const errorObject = handleError(CONNECT_MEASUREMENT, error);
      yield put(errorObject);
    }
  });
}

function* watchData() {
  yield takeLatest(FETCH_MEASUREMENT_DATA, function* ({ payload: id }) {
    try {
      const response = yield apiRequest(`/measurements/${id}/data`, 'GET');
      yield put({ payload: response.data, type: FETCH_MEASUREMENT_DATA + FULFILLED });
    } catch (error) {
      const errorObject = handleError(FETCH_MEASUREMENT_DATA, error);
      yield put(errorObject);
    }
  });
}

function* watchDisconnect() {
  yield takeLatest(DISCONNECT_MEASUREMENT, function* ({ payload: id }) {
    try {
      const response = yield apiRequest(`/measurements/${id}/disconnect`, 'PUT');
      yield put({ type: DISCONNECT_MEASUREMENT + FULFILLED });
      yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
    } catch (error) {
      const errorObject = handleError(DISCONNECT_MEASUREMENT, error);
      yield put(errorObject);
    }
  });
}

function* watchStopMeasurement() {
  yield takeLatest(STOP_MEASUREMENT, function* ({ payload: id }) {
    try {
      const response = yield apiRequest(`/measurements/${id}/stop`, 'POST');
      yield put({ type: STOP_MEASUREMENT + FULFILLED });
      yield put({ type: FETCH_MEASUREMENT + FULFILLED, payload: response.data });
    } catch (error) {
      const errorObject = handleError(STOP_MEASUREMENT, error);
      yield put(errorObject);
    }
  });
}

sagas.push(
  fork(watchConnect),
  fork(watchData),
  fork(watchDisconnect),
  fork(watchStopMeasurement)
);

export { sagas };
