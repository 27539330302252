import { FULFILLED, REJECTED } from 'ducks/actionTypes';
import arrayToObject from 'utils/arrayToObject';
import { searchFactory } from 'utils/factories';
import pluralize from 'utils/pluralize';

let allChecked, checked, ids, models;

export default TYPE => {
  const searchObject = searchFactory();
  const PLURAL = pluralize(TYPE);
  const filterIds = state => ({ ...state, ids: searchObject.searchIds(state) });

  return (state, { meta, payload, type }) => {
    switch (type) {
      case `CHANGE_${PLURAL}_SORT`:
        return filterIds({ ...state, sortBy: payload.sortBy, sortDirection: payload.sortDirection });

      case `CHANGE_${PLURAL}_SEARCH`:
        if (state.allChecked) {
          return { ...state, searching: true, searchValue: payload };
        } else {
          return { ...state, checked: new Set, searching: true, searchValue: payload };
        }

      case `CHANGE_${PLURAL}_SEARCH` + FULFILLED:
        return filterIds({ ...state, searching: false });

      case `FETCH_${TYPE}`:
      case `FETCH_${PLURAL}`:
        return { ...state, errorMessage: '', loading: true };

      case `CREATE_${TYPE}`:
      case `UPDATE_${TYPE}`:
      case `DELETE_${TYPE}`:
        return { ...state, errorMessage: '', loading: true, submitting: true };

      case `CREATE_${TYPE}` + FULFILLED:
        ids = [...state.ids, payload.id];
        models = { ...state.models, [payload.id]: payload };
        searchObject.rebuild(Object.values(models), state.searchableKeys);
        return filterIds({ ...state, errorMessage: '', ids, loading: false, models, submitting: false });

      case `FETCH_${TYPE}` + FULFILLED:
        ids = state.models[payload.id] ? state.ids : [...state.ids, payload.id];
        models = { ...state.models, [payload.id]: payload };
        searchObject.rebuild(Object.values(models), state.searchableKeys);
        return filterIds({ ...state, errorMessage: '', ids, loading: false, models });

      case `UPDATE_${TYPE}` + FULFILLED:
        models = { ...state.models, [payload.id]: payload };
        searchObject.rebuild(Object.values(models), state.searchableKeys);
        return filterIds({ ...state, errorMessage: '', loading: false, models, submitting: false });

      case `DELETE_${TYPE}` + FULFILLED:
        models = { ...state.models };
        delete models[payload];
        searchObject.rebuild(Object.values(models), state.searchableKeys);
        return filterIds({ ...state, errorMessage: '', loading: false, models, submitting: false });

      case `FETCH_${PLURAL}` + FULFILLED:
        if (meta && meta.refresh) {
          searchObject.rebuild(payload, state.searchableKeys);
          return filterIds({ ...state, errorMessage: '', loaded: true, loading: false, models: arrayToObject(payload) });
        }

        ids = [...state.ids];
        models = { ...state.models };
        payload.forEach(model => {
          if (!models[model.id]) {
            ids.push(model.id);
          }
          models[model.id] = model;
        });
        searchObject.rebuild(Object.values(models), state.searchableKeys);
        return filterIds({ ...state, errorMessage: '', ids, loaded: true, loading: false, models });

      case `FETCH_${PLURAL}` + REJECTED:
      case `FETCH_${TYPE}` + REJECTED:
        return { ...state, loading: false, errorMessage: payload };

      case `CREATE_${TYPE}` + REJECTED:
      case `UPDATE_${TYPE}` + REJECTED:
      case `DELETE_${TYPE}` + REJECTED:
        return { ...state, loading: false, errorMessage: payload, submitting: false };

      case `TOGGLE_ALL_${PLURAL}`:
        return { ...state, allChecked: !state.allChecked, checked: new Set };

      case `TOGGLE_ONE_${TYPE}`:
        if (state.allChecked) {
          checked = new Set(Object.keys(state.models));
          checked.delete(payload);
          allChecked = false;
        } else {
          checked = new Set(state.checked);
          checked.has(payload) ? checked.delete(payload) : checked.add(payload);
        }

        return { ...state, allChecked, checked };

      case `CLEAR_${PLURAL}`:
        ids = new Set(state.ids);
        models = { ...state.models };
        Object.values(models).forEach(model => {
          if (model.building_id === payload) {
            delete (models[model.id]);
            ids.delete(model.id);
          }
        });
        return { ...state, ids: Array.from(ids), models };

      default:
        return state;
    }
  };
};
