import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

const Component = ({ classes, chips, handleDelete }) => (

  <div className={classes.root}>
    {
      chips.map((data, index) => (
        <Chip
          className={classes.chip}
          classes={{ root: classes.rootChip, label: classes.label, deleteIcon: classes.icon }}
          key={index}
          label={data}
          onDelete={handleDelete(data)}
          size='small'
        />
      ))
    }
  </div>
);

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  chips: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired
};
