import { ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import { logout } from 'ducks/authentication';
import { closeMenu } from 'ducks/ui';
import React from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import globals from '~/globals';
import history from '~/history';
import styles from './styles';

const generateMenu = (classes, list) => list.map(item => (
    <MenuItem className={classes.item} key={item.icon} onClick={item.onClick}>
      <ListItemIcon className={classes.icon}>
        <Icon icon={icons[item.icon]}/>
      </ListItemIcon>
      <Typography className={classes.text} variant='inherit'>{item.text}</Typography>
    </MenuItem>
  )
);

const Component = props => {
  const { anchorEl, classes, currentUser = {}, showMenu } = props;
  const { closeMenu, logout } = props;

  const manageProfile = () => {
    history.push('/profile');
    closeMenu();
  };

  const list = [];

  if (currentUser.superuser) {
    list.push({ icon: 'delays', onClick: () => window.open(`${globals.apiUrl}/static/report.html`, '_blank'), text: 'Delays Report' });
  }

  list.push(
    { icon: 'wikipedia', onClick: () => window.open('http://wiki.wisemetering.com', '_blank'), text: 'Wiki' },
    { icon: 'profile', onClick: manageProfile, text: 'Profile' },
    { icon: 'logout', onClick: logout, text: 'Logout' }
  );

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={closeMenu}
      open={showMenu}
      PaperProps={{ style: { transform: 'translate(14px, 12px)' } }}
    >
      <MenuList className={classes.menu} onMouseLeave={closeMenu}>
        {generateMenu(classes, list)}
      </MenuList>
    </Menu>
  );
};

const mapStateToProps = state => ({
  currentUser: state.users.models[state.auth.currentUserId],
  showMenu: state.ui.showMenu
});

const mapDispatchToProps = ({ closeMenu, logout });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
