import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as indicatorsActions } from 'ducks/indicators';
import moment from 'moment/moment';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const formSchema = [
  {
    type: 'text',
    label: 'Name\x2a',
    name: 'name',
    validate: [validators.required]
  },
  {
    type: 'checkBox',
    label: 'Ignore status',
    name: 'ignore_status'
  },
  {
    type: 'textArea',
    label: 'Description',
    name: 'description'
  },
  {
    type: 'text',
    label: 'Max value (if omitted will be inherited)',
    name: 'max_value'
  },
  {
    type: 'text',
    label: 'Min value (if omitted will be inherited)',
    name: 'min_value'
  },
  {
    type: 'dateTime',
    label: 'Measurement Start',
    name: 'start_time',
    transformation: value => moment(value, 'YYYY-MM-DDTHH:mm').unix()
  }
];

const Component = props => {
  const { closeDrawer, indicator, submitting } = props;
  const { update } = props;

  const onSubmit = values => {
    update(
      indicator.id,
      serializeForm(values, formSchema, indicator),
      closeDrawer
    );
  };

  return (
    <FormViewer label={'Indicator Form'}>
      <Form
        formSchema={formSchema}
        formInitialValues={indicator}
        isSubmitting={submitting}
        onSubmit={onSubmit}
      />
    </FormViewer>
  );
};

const mapStateToProps = state => ({
  submitting: state.indicators.submitting
});

const mapDispatchToProps = ({
  update: indicatorsActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
