import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import SearchInput from 'Components/SearchInput';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Summary = ({ ids, loading, models, searching }) => {
  if (loading || searching) {
    return <Icon icon={icons.spinner} spin/>;
  } else {
    const total = Object.keys(models).length;
    return <div>{ids.length === total ? `${total} records` : `${ids.length} of ${total}`}</div>;
  }
};

const Toolbar = props => {
  const { classes, changeSearch, ids, loading, models, searching, searchValue, TableActions } = props;

  return (
    <React.Fragment>
      <Grid className={classes.menu} item xs={4}>
        {TableActions}
      </Grid>
      <Grid className={classes.search} item xs={4}>
        <SearchInput
          loading={loading}
          onSearchChange={changeSearch}
          searching={searching}
          searchValue={searchValue}
        />
      </Grid>
      <Grid className={classes.summary} item xs={4}>
        <Summary
          ids={ids}
          loading={loading}
          models={models}
          searching={searching}
        />
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(Toolbar);
