import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

const buildingsModels = state => state.buildings.models;
const currentBuildingId = (state, ownProps) => ownProps.buildingId;

export const organizationBuildings = createSelector(
  [buildingsModels, currentBuildingId],
  (buildingsModels, currentBuildingId) => {
    const buildings = Object.values(buildingsModels).filter(building =>
      building.organization_id === buildingsModels[currentBuildingId].organization_id
    );

    return sortBy(buildings, 'name');
  }
);
